import { Component, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CustomStore, createStore } from 'devextreme-aspnet-data-nojquery';
import { Expense } from '../../shared/models/expense';
import { UploadedEvent, UploadErrorEvent, ValueChangedEvent } from 'devextreme/ui/file_uploader';
import { DxFileUploaderComponent } from 'devextreme-angular/ui/file-uploader';
import { ColumnEditCellTemplateData, EditorPreparingEvent, RowDblClickEvent, SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { ClickEvent } from 'devextreme/ui/button';
import { environment } from '../../../environments/environment';
import { DxDataGridComponent } from 'devextreme-angular';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToolbarService } from '../../shared/services/toolbar.service';

@Component({
  selector: 'app-doctors',
  templateUrl: './doctors.component.html',
  styleUrl: './doctors.component.scss'
})
export class DoctorsComponent {
  backendURL: string = environment.ApiUrl + '/api';

  remoteDataSource: any;
  investmentRequestSpecializationDS: any;
  workPlaceDS: any;
  informatoriDS: CustomStore;

  @ViewChild(DxDataGridComponent, { static: false }) grid!: DxDataGridComponent;

  selectedRowIndex = -1;

  private unsubscriber: Subject<void> = new Subject<void>();

  constructor(
    private toolbar: ToolbarService) {

    const currentUser = localStorage.getItem("userId");

    this.remoteDataSource = createStore({
      key: 'doctorId',
      loadUrl: this.backendURL + '/Doctors/GetbyUserId?userName=' + currentUser,
      insertUrl: this.backendURL + '/Doctors/Post',
      updateUrl: this.backendURL + '/Doctors/Put',
      deleteUrl: this.backendURL + '/Doctors/Delete',
    });

    this.workPlaceDS = createStore({
      key: 'districtId',
      loadUrl: this.backendURL + '/Districts/Get',
    });

    this.investmentRequestSpecializationDS = createStore({
      key: 'couponSpecializationId',
      loadUrl: this.backendURL + '/CouponSpecializations/Get',
    });

    this.informatoriDS = createStore({
      key: 'userId',
      loadUrl: this.backendURL + '/Utenti/GetAgenti?userName=' + currentUser,
    });

    }

  onRowDblClick(e: RowDblClickEvent) {
    this.selectedRowIndex = e.rowIndex;
    this.grid.instance.editRow(this.selectedRowIndex);
    this.grid.instance.deselectAll();
  }

  onInitNewRow(e: { data: { userName: string | null; informatoreId: string | null; }; }) {
    e.data.userName = localStorage.getItem("userId");
    e.data.informatoreId = localStorage.getItem("userId");
  }

  onEditorPreparing(e: EditorPreparingEvent) {
    if (e.dataField == "workPlaceId") {
      e.editorName = "dxLookup";  // "dxSelectBox" or "dxDropDownBox"  
      e.editorOptions.searchEnabled = true;
      e.editorOptions.searchExpr = "descrizione"
      e.editorOptions.searchMode = "contains"
      e.editorOptions.showClearButton = true;
      e.editorOptions.usePopover = false;
      e.editorOptions.wrapItemText = true;
      e.editorOptions.placeholder = "Selezionare il Comune"
      e.editorOptions.dropDownOptions = {
        fullScreen: true,
        showTitle: false
      }
    }
  }

  editRow() {
    this.grid.instance.editRow(this.selectedRowIndex);
    this.grid.instance.deselectAll();
  }

  deleteRow() {
    this.grid.instance.deleteRow(this.selectedRowIndex);
    this.grid.instance.deselectAll();
  }

  addRow() {
    this.grid.instance.addRow();
    this.grid.instance.deselectAll();
  }

  selectedChanged(e: SelectionChangedEvent) {
    this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
  }

  ngOnInit(): void {
    this.toolbar.TitleChanged("Archivio Medici");
    this.toolbar.CollapseVisibleChanged(false);
    this.toolbar.RefreshVisibleChanged(false);
    this.toolbar.BackVisibleChanged(false);
    this.toolbar.ChartVisibleChanged(false);
   
    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe(() => {
      history.pushState(null, '');

    });
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
