import { Component, NgModule, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthService, UserForAuthenticationDto } from '../../services';
import { UserPanelModule } from '../user-panel/user-panel.component';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { HeaderButtonModule } from '../headerbuttons/headerbuttons.component';

import { Router } from '@angular/router';
import { ToolbarService } from '../../services/toolbar.service';
import { DxiToolbarItemComponent } from 'devextreme-angular/ui/nested';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;

  @ViewChild('collapseExpand') collapseExpandRef!: DxiToolbarItemComponent;

  @Input()
  title!: string;

  user: UserForAuthenticationDto | null = { Username: '', Password: '' };
  userName = localStorage.getItem("nomeUtente");
  isAreaManager = localStorage.getItem("isAreaManager");
  isNoteSpese = localStorage.getItem("isNoteSpese");

  refreshVisible = false;
  collapseVisible = false;
  expandVisible = false;
  chartVisible = false;
  backVisible = false;

  private unsubscriber: Subject<void> = new Subject<void>();

  backButtonOptions = {
    icon: 'arrowleft',
    stylingMode: 'outlined',
    text: 'Indietro',
    onClick: () => {
      this.toolbarService.BackClicked();
    }
  }

  chartButtonOptions = {
    icon: 'chart',
    stylingMode: 'outlined',
    text: 'Report',
    onClick: () => {
      this.toolbarService.ChartClicked();
    }
  }

  collapseButtonOptions = {
    icon: 'collapse',
    onClick: () => {
      this.toolbarService.CollapseClicked(true);
      this.collapseVisible = false;
      this.expandVisible = true;
    }
  }

  expandButtonOptions = {
    icon: 'expand',
    onClick: () => {
      this.toolbarService.CollapseClicked(false);
      this.collapseVisible = true;
      this.expandVisible = false;
    }
  }

  refreshButtonOptions = {
    icon: 'refresh',
    onClick: () => {
      this.toolbarService.RefreshClicked();
    }
  }

  userMenuItems = [{
    text: this.userName,
    icon: 'user',   
  },
  {
    text: 'Logout',
    icon: 'runner',
    onClick: () => {
      this.authService.logOut();
    }
    }];

  constructor(
    private authService: AuthService,
    private router: Router,
    private toolbarService: ToolbarService)
  {
    
  }

  ngOnInit() {
    this.title = this.toolbarService.title;

    this.refreshVisible = this.toolbarService.refreshVisible;
    this.collapseVisible = this.toolbarService.collapseVisible;

    this.toolbarService.titleChangedEvent.pipe(takeUntil(this.unsubscriber)).subscribe(val => {
      this.title = val;
    });

    this.toolbarService.backVisibleChangedEvent.pipe(takeUntil(this.unsubscriber)).subscribe(val => this.backVisible = val);

    this.toolbarService.chartVisibleChangedEvent.pipe(takeUntil(this.unsubscriber)).subscribe(val => this.chartVisible = val);

    this.toolbarService.collapseVisibleChangedEvent.pipe(takeUntil(this.unsubscriber)).subscribe(val => {
      if (val) {
        this.collapseVisible = true;
        this.expandVisible = false;
      }
      else {
        this.collapseVisible = false;
        this.expandVisible = false;
      }
    });

    this.toolbarService.refreshVisibleChangedEvent.pipe(takeUntil(this.unsubscriber)).subscribe(val => this.refreshVisible = val);
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    HeaderButtonModule,
  ],
  declarations: [ HeaderComponent ],
  exports: [ HeaderComponent ]
})
export class HeaderModule { }
