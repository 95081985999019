import { Component, ViewChild } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggingService } from '../../shared/services/logging.service';
import { environment } from '../../../environments/environment';
import { InvestmentRequestKind, InvestmentRequestDoctor, InvestmentRequest } from '../../shared/models/investment-request';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { UploadedEvent, UploadErrorEvent, ValueChangedEvent } from 'devextreme/ui/file_uploader';
import { DxButtonTypes } from 'devextreme-angular/ui/button';
import { DxFileUploaderComponent, DxFormComponent } from 'devextreme-angular';
import { Subject, fromEvent, takeUntil } from 'rxjs';
import { confirm } from 'devextreme/ui/dialog';
import { InvestmentRequestsDbService } from '../../shared/services/investment-requests-db.service';
import { RestApiService } from '../../shared/services/rest-api.service';

@Component({
  selector: 'app-investment-request-doctor',
  templateUrl: './investment-request-doctor.component.html',
  styleUrl: './investment-request-doctor.component.scss'
})
export class InvestmentRequestDoctorComponent {
  @ViewChild(DxFormComponent, { static: true }) form!: DxFormComponent;
  @ViewChild('fileUploaderMedico') fileUploaderMedicoRef!: DxFileUploaderComponent;
  @ViewChild('fileUploaderAccompagnatore1') fileUploaderAccompagnatore1Ref!: DxFileUploaderComponent;
  @ViewChild('fileUploaderAccompagnatore2') fileUploaderAccompagnatore2Ref!: DxFileUploaderComponent;

  specializationDataSource: DataSource;
  districtDataSource: DataSource;

  specializationEditorOptions: unknown;
  workPlaceEditorOptions: unknown;

  isFormReadOnly: boolean = false;
  isAreaManager = (localStorage.getItem("isAreaManager") == "S");
  isAdministrator = (localStorage.getItem("isAdministrator") == "S");
  travelTreatmentRequired: boolean = false;

  emailEditorOption = { mode: "email" };

  phoneEditorOption = { mode: "tel" };

  travelTreatmentEditorOptions = {
    placeholder: "Selezionare il trattamento",
    items: [
      'BB - Bed & Breakfast',
      'HB - Mezza Pensione',
    ],
    showClearButton: "true"
  };

  travelRoomEditorOptions = {
    placeholder: "Selezionare il tipo di camera",
    items: [
      'DUS - Doppia uso singola',
      'DBL - Doppia',
      'TPL - Tripla',
    ],
    showClearButton: "true"
  };

  aggiungiMedicoButtonOptions: DxButtonTypes.Properties = {
    text: 'Aggiungi Medico',
    type: 'default',
    width: '180px',
    onClick: () => {
      const result = this.form.instance.validate();

      if (result.isValid && this.investmentRequest) {

        if (!this.investmentRequest.investmentRequestDoctors) this.investmentRequest.investmentRequestDoctors = [];

        if (this.investmentRequestDoctorId) {
          const index = this.investmentRequest.investmentRequestDoctors.findIndex(f => f.investmentRequestDoctorId == this.investmentRequestDoctorId);

          if (index !== undefined && index > -1) {
            this.investmentRequest.investmentRequestDoctors.splice(index, 1);
          }

          this.investmentRequest.investmentRequestDoctors.push(this.investmentRequestDoctor);
        }
        else {
          let minId = 0;
          this.investmentRequest.investmentRequestDoctors.forEach(f => { if (f.investmentRequestDoctorId < minId) minId = f.investmentRequestDoctorId; });

          this.investmentRequestDoctor.investmentRequestDoctorId = minId - 1;

          this.investmentRequest.investmentRequestDoctors.push(this.investmentRequestDoctor);
        }


        this.investmentRequestDb.updateInvestmentRequest(this.investmentRequest).then(()=>this.router.navigate(['/investment-request', { id: this.investmentRequestId }]));
        
      }
    }
  };

  investmentRequestDoctor: InvestmentRequestDoctor;
  currentInvestmentRequestKind: InvestmentRequestKind | null = null;
  allInvestmentRequestKinds = InvestmentRequestKind;
  investmentRequest: InvestmentRequest | undefined;
  investmentRequestId: number | null;
  investmentRequestDoctorId: number | null;
  backendURL: string = environment.ApiUrl + '/api';
  
  private unsubscriber: Subject<void> = new Subject<void>();

  constructor(
    private toolbar: ToolbarService,
    private route: ActivatedRoute,
    private logger: LoggingService,
    private restApi: RestApiService,
    private investmentRequestDb: InvestmentRequestsDbService,
    private router: Router) {

    this.investmentRequestId = null;
    this.investmentRequestDoctorId = null;
    this.investmentRequestDoctor = new InvestmentRequestDoctor(0, 0);

    this.specializationDataSource = new DataSource({
      store: createStore({
        key: 'couponSpecializationId',
        loadUrl: this.backendURL + '/CouponSpecializations/Get'
      }),
      sort: 'descrizione'
    });

    this.specializationEditorOptions = {
      dataSource: this.specializationDataSource,
      searchEnabled: true,
      sort: "descrizione",
      displayExpr: "descrizione",
      valueExpr: "couponSpecializationId",
      showClearButton: true,
      searchExpr: "descrizione",
      searchMode: "contains",
      usePopover: false,
      wrapItemText: true,
      placeholder: "Selezionare la specializzazione",
      dropDownOptions: {
        fullScreen: true,
        showTitle: false
      }
    };

    this.districtDataSource = new DataSource({
      store: createStore({
        key: 'districtId',
        loadUrl: this.backendURL + '/Districts/Get'
      }),
      sort: 'descrizione'
    });

    this.workPlaceEditorOptions = {
      dataSource: this.districtDataSource,
      searchEnabled: true,
      sort: "descrizione",
      displayExpr: "descrizione",
      valueExpr: "districtId",
      showClearButton: true,
      searchExpr: "descrizione",
      searchMode: "contains",
      usePopover: false,
      wrapItemText: true,
      placeholder: "Selezionare il comune",
      dropDownOptions: {
        fullScreen: true,
        showTitle: false
      }
    };
  }

  ngOnInit(): void {
    this.toolbar.TitleChanged("Richiesta Investimento");
    this.toolbar.CollapseVisibleChanged(false);
    this.toolbar.RefreshVisibleChanged(false);
    this.toolbar.BackVisibleChanged(true);
    this.toolbar.ChartVisibleChanged(false);

    this.toolbar.backClickedEvent.pipe(takeUntil(this.unsubscriber)).subscribe(() => this.Back());

    const regId = this.route.snapshot.paramMap.get('id');
    if (regId) {
      this.investmentRequestId = Number(regId);
      this.investmentRequestDb.getInvestmentRequest(this.investmentRequestId).then(i => {
          this.investmentRequest = i;

          if (this.investmentRequest) {
            this.currentInvestmentRequestKind = this.investmentRequest.investmentRequestTypeId as InvestmentRequestKind;
            this.travelTreatmentRequired = this.investmentRequest.companyVisitCheckIn !== this.investmentRequest.companyVisitCheckOut
          }
          else {
            this.currentInvestmentRequestKind = null;
          }


          const doctorId = this.route.snapshot.paramMap.get('doctorId')

          if (doctorId) {
            this.investmentRequestDoctorId = Number(doctorId);
            const existing = this.investmentRequest?.investmentRequestDoctors.find(f => f.investmentRequestDoctorId == this.investmentRequestDoctorId);

            if (existing) {
              this.investmentRequestDoctor = existing;

              this.aggiungiMedicoButtonOptions.text = "Modifica Medico";
            }
          }
        
      });
    }

    const requestId = this.route.snapshot.paramMap.get('requestId');
    if (requestId) {
      this.investmentRequestId = Number(requestId);
      this.restApi.getInvestmentRequest(this.investmentRequestId).pipe(takeUntil(this.unsubscriber)).subscribe(result => {
        this.investmentRequest = result;

        if (this.investmentRequest) {
          this.currentInvestmentRequestKind = this.investmentRequest.investmentRequestTypeId as InvestmentRequestKind;
        }
        else {
          this.currentInvestmentRequestKind = null;
        }

        if (this.isAreaManager && this.investmentRequest.investmentRequestStateId > 2) this.isFormReadOnly = true;
        else if (!this.isAdministrator && !this.isAreaManager && this.investmentRequest.investmentRequestStateId > 1) this.isFormReadOnly = true;
        else this.isFormReadOnly = false;

        const doctorId = this.route.snapshot.paramMap.get('doctorId')

        if (doctorId) {
          this.investmentRequestDoctorId = Number(doctorId);
          const existing = this.investmentRequest?.investmentRequestDoctors.find(f => f.investmentRequestDoctorId == this.investmentRequestDoctorId);

          if (existing) {
            this.investmentRequestDoctor = existing;

            this.aggiungiMedicoButtonOptions.text = "Modifica Medico";
          }
        }
      });
    }

    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe(() => {
      history.pushState(null, '');
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  Back(): void {
    if (this.isFormReadOnly) {
      this.router.navigate(['/investment-request', { requestId: this.investmentRequestId }]);
    }
    else {
      const result = confirm("<i>Sei sicuro?</i><br>I dati inseriti andranno persi", "Conferma Abbandono");
      result.then((dialogResult) => {
        if (dialogResult) {
          // Torno all'indice
          this.router.navigate(['/investment-request', { id: this.investmentRequestId }]);
        }
      });

    }
  }

  onValueChangedMedico(e: ValueChangedEvent): void {
    const reader: FileReader = new FileReader();
    //reader.onload = (args) => {
    //  if (typeof args.target?.result === 'string' && this.uploadedImageRef) {
    //    this.uploadedImageRef.src = args.target.result;
    //  }
    //};
    reader.readAsDataURL(e.value![0]); // convert to base64 string
  }

  onValueChangedAccompagnatore1(e: ValueChangedEvent): void {
    const reader: FileReader = new FileReader();
    //reader.onload = (args) => {
    //  if (typeof args.target?.result === 'string' && this.uploadedImageRef) {
    //    this.uploadedImageRef.src = args.target.result;
    //  }
    //};
    reader.readAsDataURL(e.value![0]); // convert to base64 string
  }

  onValueChangedAccompagnatore2(e: ValueChangedEvent): void {
    const reader: FileReader = new FileReader();
    //reader.onload = (args) => {
    //  if (typeof args.target?.result === 'string' && this.uploadedImageRef) {
    //    this.uploadedImageRef.src = args.target.result;
    //  }
    //};
    reader.readAsDataURL(e.value![0]); // convert to base64 string
  }

  onUploadError(e: UploadErrorEvent): void {
    const xhttp = e.request;
    if (xhttp.status === 400) {
      e.message = e.error.responseText;
    }
    if (xhttp.readyState === 4 && xhttp.status === 0) {
      e.message = 'Connection refused';
    }
  }

  onUploaded(e: UploadedEvent, cellInfo: any): void {

    let fileName = '';

    if (e.request.responseText.toLowerCase().endsWith(".pdf")) {
      fileName = 'pdfs/investmentrequests/' + e.request.responseText;
    }
    else {
      fileName = 'images/investmentrequests/' + e.request.responseText;
    }

    this.form.instance.updateData(cellInfo.dataField, fileName);
  }
}
