import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkPdfExtension'
})
export class CheckPdfExtensionPipe implements PipeTransform {

  transform(value: string): boolean {
    if (!value) {
      return false;
    }
    return value.toLowerCase().endsWith('.pdf');
  }

}
