<dx-data-grid id="grid"
              [allowColumnReordering]="true"
              [columnAutoWidth]="true"
              [dataSource]="investmentRequestDataSource"
              [repaintChangesOnly]="true"
              [wordWrapEnabled]="true"
              dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
              (onRowDblClick)="onRowDblClick($event)"
              (onRowPrepared)="onRowPrepared($event)"
              (onSelectionChanged)="selectedChanged($event)">
  <dxo-selection mode="single"></dxo-selection>
  <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
  <dxo-header-filter [visible]="true">
    <dxo-search [enabled]="true"></dxo-search>
  </dxo-header-filter>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-filter-row [visible]="false"></dxo-filter-row>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-editing [allowUpdating]="false"
               [allowAdding]="false"
               [allowDeleting]="true"
               mode="row">
  </dxo-editing>
  <dxi-column dataField="investmentRequestStateId"
              caption="Stato"
              [groupIndex]="1"
              groupCellTemplate="informatoreGroupCellTemplate">
    <dxo-lookup [dataSource]="investmentRequestStatesDataSource"
                valueExpr="investmentRequestStateId"
                displayExpr="descrizione">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="informatoreId"
              caption="Informatore"
              [groupIndex]="isAreaManager ? 2 : -1"
              calculateGroupValue="informatore"
              groupCellTemplate="informatoreGroupCellTemplate"
              [visible]="isAreaManager || isAdministrator">
    <dxo-lookup [dataSource]="informatoriDataSource"
                valueExpr="userId"
                displayExpr="nome">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="investmentRequestTypeId"
              caption="Tipo. Inv">
    <dxo-lookup [dataSource]="investmentRequestTypesDataSource"
                valueExpr="investmentRequestTypeId"
                displayExpr="descrizione">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="note"
              dataType="string"
              caption="Note"
              [hidingPriority]="4">
  </dxi-column>
  <dxi-column dataField="investmentBudgetTypeId"
              caption="Tipo Budget"
              [hidingPriority]="0">
    <dxo-lookup [dataSource]="investmentBudgetTypesDataSource"
                valueExpr="investmentBudgetTypeId"
                displayExpr="descrizione">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="importo"
              caption="Importo">
    <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
  </dxi-column>
  <dxo-summary>
    <dxi-group-item column="importo"
                    summaryType="sum"
                    [alignByColumn]="true"
                    [showInGroupFooter]="false"
                    [customizeText]="customizeTotaleImporto">
    </dxi-group-item>
  </dxo-summary>

  <div *dxTemplate="let data of 'informatoreGroupCellTemplate'">
    <div>{{data.text}}</div>
  </div>
</dx-data-grid>
<dx-speed-dial-action icon="add"
                      label="Aggiungi"
                      [index]="1"
                      (onClick)="addRow()">
</dx-speed-dial-action>
<dx-speed-dial-action icon="trash"
                      label="Cancella"
                      [index]="2"
                      [visible]="selectedRowIndex !== -1 && modificabile"
                      (onClick)="deleteRow()">
</dx-speed-dial-action>
<dx-speed-dial-action icon="edit"
                      label="Modifica"
                      [index]="3"
                      [visible]="selectedRowIndex !== -1 && modificabile"
                      (onClick)="editRow()">
</dx-speed-dial-action>
