import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CustomStore, createStore } from 'devextreme-aspnet-data-nojquery';
import { UploadedEvent, UploadErrorEvent, ValueChangedEvent } from 'devextreme/ui/file_uploader';
import { DxFileUploaderComponent } from 'devextreme-angular/ui/file-uploader';
import { ColumnEditCellTemplateData, RowDblClickEvent, RowPreparedEvent, SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { ClickEvent } from 'devextreme/ui/button';
import { Investment } from '../../shared/models/investment';
import { environment } from '../../../environments/environment';
import { DxDataGridComponent } from 'devextreme-angular';
import { confirm } from 'devextreme/ui/dialog';
import { RestApiService } from '../../shared/services/rest-api.service';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-investments',
  templateUrl: './investments.component.html',
  styleUrls: ['./investments.component.scss']
})
export class InvestmentsComponent {
  selectedRowIndex = -1;
  isLargeScreen = false;

  remoteDataSource: CustomStore;
  investmentTypesDS: CustomStore;
  investmentAuthorizationDS: CustomStore;
  paymentTypesDS: CustomStore;
  investmentBudgetTypesDS: CustomStore;
  informatoriDS: CustomStore;
  backendURL: string = environment.ApiUrl + '/api';
  imageURL: string = environment.ApiUrl + '/';
  retryButtonVisible = false;
  modificabile = true;
  completo = false;
  isAreaManager = (localStorage.getItem("isAreaManager") == "S");
  maxDate: Date;
  minDate: Date;
  isLuce = environment.isLuce;

  @ViewChild('uploadedImage') uploadedImageRef!: HTMLImageElement;
  @ViewChild('fileUploader') fileUploaderRef!: DxFileUploaderComponent;
  @ViewChild(DxDataGridComponent) grid!: DxDataGridComponent

  private unsubscriber: Subject<void> = new Subject<void>();

  constructor(
    private toolbar: ToolbarService,
    private apiService: RestApiService,
    private sanitizer: DomSanitizer) {
    const currentUser = localStorage.getItem("userId");

   
    this.remoteDataSource = createStore({
      key: 'investmentId',
      loadUrl: this.backendURL + '/Investments/GetbyUserId?userName=' + currentUser,
      insertUrl: this.backendURL + '/Investments/Post',
      updateUrl: this.backendURL + '/Investments/Put',
      deleteUrl: this.backendURL + '/Investments/Delete',
    });

    this.investmentTypesDS = createStore({
      key: 'investmentTypeId',
      loadUrl: this.backendURL + '/InvestmentTypes/Get'
    });

    this.investmentAuthorizationDS = createStore({
      key: 'investmentAuthorizationId',
      loadUrl: this.backendURL + '/InvestmentAuthorizations/Get',
    });

    this.paymentTypesDS = createStore({
      key: 'paymentTypeId',
      loadUrl: this.backendURL + '/PaymentTypes/Get',
    });

    this.investmentBudgetTypesDS = createStore({
      key: 'investmentBudgetTypeId',
      loadUrl: this.backendURL + '/InvestmentBudgetTypes/Get',
    });

    this.informatoriDS = createStore({
      key: 'userId',
      loadUrl: this.backendURL + '/Utenti/GetAgenti?userName=' + currentUser,
    });

    const today = new Date();
    this.minDate = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    this.maxDate = new Date(today.getFullYear() + 1, today.getMonth(), 1);
  }

  getPdfURL(pdfUrl: string): SafeResourceUrl {
    const fullUrl = 'https://docs.google.com/viewer?url=' + this.imageURL + '/' + pdfUrl + '&embedded=true';

    const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fullUrl);

    return safeUrl;
  }

  onInitNewRow(e: { data: Investment }) {
    const username = localStorage.getItem("userId") ?? "";

    e.data.approvazioneAreaManager = this.isAreaManager;
    e.data.informatore = username;
    e.data.userName = username;
    e.data.dataRichiesta = new Date();
    e.data.fattura = false;

    if (this.isLuce) {
      e.data.dataInvestimento = new Date();
    }

    if (!this.isAreaManager) {
      e.data.paymentTypeId = 2
    }
  }

  approvaRow() {
    const result = confirm("<i>Sei sicuro?</i><br>L'approvazione può essere annullata modificanto la riga aprovata", "Conferma Approvazione");
    result.then((dialogResult) => {
      if (dialogResult) {
        // Torno all'indice
        const rowKey = this.grid.instance.getKeyByRowIndex(this.selectedRowIndex);
        this.apiService.putApprovato(rowKey).then(
          () => { this.grid.instance.getDataSource().reload(); }
        );
      }
    });
  }

  editRow() {
    this.grid.instance.editRow(this.selectedRowIndex);
    this.grid.instance.deselectAll();
  }

  deleteRow() {
      this.grid.instance.deleteRow(this.selectedRowIndex);
      this.grid.instance.deselectAll();
  }

  addRow() {
    this.grid.instance.addRow();
    this.grid.instance.deselectAll();
  }

  selectedChanged(e: SelectionChangedEvent) {
    let controllata = false, approvata = false, completa = false;
    if (e.selectedRowsData.length > 0 && e.selectedRowsData[0].controllata) controllata = true;
    if (e.selectedRowsData.length > 0 && e.selectedRowsData[0].approvazioneAreaManager) approvata = true;
    if (e.selectedRowsData.length > 0) {
      if (
        e.selectedRowsData[0].paymentTypeId &&
        e.selectedRowsData[0].investmentAuthorizationId &&
        e.selectedRowsData[0].investmentBudgetTypeId
      ) {
        completa = true;
      }
    }

    if (controllata || (approvata && !this.isAreaManager)) {
      this.modificabile = false;
    }
    else {
      this.modificabile = true;
    }

    this.completo = completa;
    this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
  }

  onRowDblClick(e: RowDblClickEvent) {
    this.selectedRowIndex = e.rowIndex;
    this.grid.instance.editRow(this.selectedRowIndex);
    this.grid.instance.deselectAll();
  }

  onRowPrepared(e: RowPreparedEvent) {
    if (e.rowType === "group" && e.groupIndex === 0 && e.rowElement && e.rowElement.childNodes && e.rowElement.childNodes.length > 0) {

      const tableSectionElement = e.rowElement as HTMLTableSectionElement;

      if (tableSectionElement) {

        if (e.values[0]) {
          e.rowElement.childNodes.forEach(f => {
            const html = f as HTMLTableCellElement;
            html.classList.add("group-approvato");
          });
        }
        else {
          e.rowElement.childNodes.forEach(f => {
            const html = f as HTMLTableCellElement;
            html.classList.add("group-nonapprovato");
          });
        }
      }
    }
    if (e.rowType === "group" && e.groupIndex === 1 && e.rowElement && e.rowElement.childNodes && e.rowElement.childNodes.length > 0) {

      const tableSectionElement = e.rowElement as HTMLTableSectionElement;

      if (tableSectionElement) {
        e.rowElement.childNodes.forEach(f => {
          const html = f as HTMLTableCellElement;
          html.classList.add("group-informatore");
        });
      }
    }
  }

  onClick(e: ClickEvent): void {
    // The retry UI/API is not implemented. Use the private API as shown at T611719.
    const fileUploaderInstance = this.fileUploaderRef.instance;
    // @ts-ignore
    for (let i = 0; i < fileUploaderInstance._files.length; i++) {
      // @ts-ignore
      delete fileUploaderInstance._files[i].uploadStarted;
    }
    fileUploaderInstance.upload();
  }

  onValueChanged(e: ValueChangedEvent): void {
    const reader: FileReader = new FileReader();
    reader.onload = (args) => {
      if (typeof args.target?.result === 'string' && this.uploadedImageRef) {
        this.uploadedImageRef.src = args.target.result;
      }
    };
    reader.readAsDataURL(e.value![0]); // convert to base64 string
  }

  onUploaded(e: UploadedEvent, cellInfo: ColumnEditCellTemplateData<Investment, number>): void {

    if (e.request.responseText.toLowerCase().endsWith(".pdf"))
    {
      cellInfo.setValue('pdfs/investments/' + e.request.responseText);
    }
    else {
      cellInfo.setValue('images/investments/' + e.request.responseText);
    }

    this.retryButtonVisible = false;
  }

  onUploadError(e: UploadErrorEvent): void {
    const xhttp = e.request;
    if (xhttp.status === 400) {
      e.message = e.error.responseText;
    }
    if (xhttp.readyState === 4 && xhttp.status === 0) {
      e.message = 'Connection refused';
    }
    this.retryButtonVisible = true;
  }

  customizeTotaleImporto(cellInfo: { value: string; }) {
    if (cellInfo.value == "0") return "";
    return Number(cellInfo.value).toLocaleString("it-IT", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  }

  Collapse(collapse: boolean) {
    if (collapse) {
      this.grid.instance.collapseAll(1);
      this.grid.instance.collapseAll(0);
    }
    else {
      this.grid.instance.expandAll(1);
      this.grid.instance.expandAll(0);
    }
  }

  Refresh() {
    this.grid.instance.refresh();
  }

  
  ngOnInit(): void {
    this.toolbar.TitleChanged("Investimenti");
    this.toolbar.CollapseVisibleChanged(true);
    this.toolbar.RefreshVisibleChanged(true);
    this.toolbar.BackVisibleChanged(false);
    this.toolbar.ChartVisibleChanged(false);

    this.toolbar.refreshClickedEvent.pipe(takeUntil(this.unsubscriber)).subscribe(() => this.Refresh());
    this.toolbar.collapseClickedEvent.pipe(takeUntil(this.unsubscriber)).subscribe((val) => this.Collapse(val));

    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
