import { Component, NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserForAuthenticationDto } from '../../services/auth.service';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-headerbuttons',
  templateUrl: './headerbuttons.component.html',
  styleUrls: ['./headerbuttons.component.scss']
})
export class HeaderbuttonsComponent {
  constructor() { }
}

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [HeaderbuttonsComponent],
  exports: [HeaderbuttonsComponent]
})
export class HeaderButtonModule { }
