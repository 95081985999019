<form class="login-form" (submit)="onSubmit($event)">
  <dx-form [formData]="formData" [disabled]="loading">

    <dxi-item dataField="email" editorType="dxTextBox"
      [editorOptions]="{ stylingMode: 'filled', placeholder: 'Nome Utente' }">
      <dxi-validation-rule type="required" message="Il nome utente è richiesto"></dxi-validation-rule>
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item dataField="password" editorType="dxTextBox"
      [editorOptions]="{ stylingMode: 'filled', placeholder: 'Password', mode: 'password' }">
      <dxi-validation-rule type="required" message="La Password è richiesta"></dxi-validation-rule>
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item dataField="rememberMe" editorType="dxCheckBox"
      [editorOptions]="{ text: 'Ricordami', elementAttr: { class: 'form-text' } }">
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item itemType="button">
      <dxo-button-options width="100%" type="default" [useSubmitBehavior]="true" [template]="'signInTemplate'">
      </dxo-button-options>
    </dxi-item>

    <ng-container *dxTemplate="let item of 'signInTemplate'">
      <div>
        <span class="dx-button-text">
          @if (loading) {
            <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator>
          } @else {
            Accedi
          }
        </span>
      </div>
    </ng-container>

  </dx-form>
</form>
