@if (isAuthenticated()) {
  <app-side-nav-outer-toolbar title="{{appInfo.title}}">
    <div>
      <router-outlet></router-outlet>
    </div>
    <app-footer>
      Copyright © 2022-{{appInfo.currentYear}} Project Srl v{{appInfo.version}}
    </app-footer>
  </app-side-nav-outer-toolbar>
} @else {
  <app-unauthenticated-content></app-unauthenticated-content>
}

