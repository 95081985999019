import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(private logger: NGXLogger) { }

  logError(message: string, stack: string) {

    const additionalInformtion: string[] = [];
    additionalInformtion.push(stack);

    const username = localStorage.getItem("userId") ?? "";
    additionalInformtion.push(username);

    this.logger.error(message, stack, username);
    }
}
