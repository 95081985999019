import { Injectable } from '@angular/core';

@Injectable()
export class AppInfoService {
  constructor() {}

  public get title() {
    return 'Spese';
  }

  public get currentYear() {
    return new Date().getFullYear();
  }

  public get version() {
    return '1.2.2';
  }
}
