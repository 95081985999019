import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { InvestmentRequest } from '../models/investment-request';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {

  private apiUrl;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.ApiUrl + '/';
  }

  putApprovato(reg: number): Promise<Object|undefined> {
    return this.http.put(this.apiUrl + 'api/Investments/PutApprovato?key=' + reg, { key: reg }).toPromise();
  }

  getInvestmentRequest(id: number): Observable<InvestmentRequest> {
    return this.http.get<InvestmentRequest>(this.apiUrl + 'api/InvestmentRequests/GetById?id=' + id);
  }

  putInvestmentRequest(investmentRequest: InvestmentRequest): Observable<Object> {
    return this.http.put(this.apiUrl + 'api/InvestmentRequests/Save?key=' + investmentRequest.investmentRequestId, investmentRequest);
  }
}
