<dx-data-grid #grid
              [allowColumnReordering]="true"
              [columnAutoWidth]="true"
              [dataSource]="remoteDataSource"
              [repaintChangesOnly]="true"
              [wordWrapEnabled]="true"
              (onInitNewRow)="onInitNewRow($event)"
              (onRowDblClick)="onRowDblClick($event)"
              (onSelectionChanged)="selectedChanged($event)"
              keyField="expenseId">
  <dxo-selection mode="single"></dxo-selection>
  <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
  <dxo-editing mode="popup">
    <dxo-popup title="Nota Spese"
               [showTitle]="true">
    </dxo-popup>
    <dxo-form>
      <dxi-item dataField="data"></dxi-item>
      <dxi-item dataField="importo"></dxi-item>
      <dxi-item dataField="expenseReasonId"></dxi-item>
      <dxi-item dataField="paymentTypeId"></dxi-item>
      <dxi-item dataField="note"
                editorType="dxTextArea"
                [colSpan]="2"
                [editorOptions]="{ height: 100 }">
      </dxi-item>
      <dxi-item dataField="fattura"></dxi-item>
      <dxi-item dataField="userName"
                [visible]="false">
      </dxi-item>
      <dxi-item dataField="foto"></dxi-item>
    </dxo-form>
  </dxo-editing>
  <dxo-filter-row [visible]="false"></dxo-filter-row>
  <dxo-header-filter [visible]="true">
    <dxo-search [enabled]="true"></dxo-search>
  </dxo-header-filter>
  <dxo-search-panel [visible]="false"></dxo-search-panel>
  <dxi-column dataField="mese"
              dataType="date"
              sortOrder="desc"
              [format]="{ type: 'MMMM yyyy' }"
              [groupIndex]="0">
  </dxi-column>
  <dxi-column dataField="data"
              dataType="date"
              sortOrder="desc"
              caption=""
              [width]="80"
              [format]="{ type: 'dd MMM' }">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxi-validation-rule type="range"
                         [min]="minDate"
                         [max]="maxDate"
                         message="La data deve essere compresa tra il mese scorso e l'anno prossimo">
    </dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="expenseReasonId"
              caption="Causale"
              [width]="120">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxo-lookup [dataSource]="expenseReasonDS"
                valueExpr="expenseReasonId"
                displayExpr="descrizione">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="importo"
              [width]="100">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
  </dxi-column>

  <dxi-column dataField="paymentTypeId"
              caption="Tipo Pagamento"
              [hidingPriority]="0">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxo-lookup [dataSource]="paymentTypeDS"
                valueExpr="paymentTypeId"
                displayExpr="descrizione">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="note"
              dataType="string"
              cellTemplate="noteTemplate"
              caption="Note"
              [hidingPriority]="2">
  </dxi-column>
  <dxi-column dataField="fattura"
              dataType="boolean"
              caption="Fattura"
              [hidingPriority]="1">
  </dxi-column>
  <dxi-column dataField="userName"
              dataType="string"
              [visible]="false"
              [allowEditing]="false">
  </dxi-column>
  <dxi-column dataField="foto"
              dataType="string"
              caption=""
              [width]="50"
              [visible]="true"
              [allowFiltering]="false"
              [allowSorting]="false"
              cellTemplate="cellTemplate"
              editCellTemplate="editCellTemplate">
  </dxi-column>
  <dxo-summary>
    <dxi-group-item column="importo"
                    summaryType="sum"
                    [customizeText]="customizeTotaleImporto"
                    [showInGroupFooter]="true"
                    [alignByColumn]="true">
    </dxi-group-item>
  </dxo-summary>
  <div *dxTemplate="let data of 'cellTemplate'">
    @if (data.value) {
    <i class="dx-icon-photo"></i>
    }
  </div>
  <div *dxTemplate="let data of 'editCellTemplate'">
    @if (data.value  && !(data.value | checkPdfExtension)) {
    <img #uploadedImage
         class="uploadedImage"
         [src]="imageURL + data.value" />
    }
    @if (data.value | checkPdfExtension) {
    <iframe class="uploadedImage"
            [src]="getPdfURL(data.value)">
    </iframe>
    }

    <dx-file-uploader #fileUploader
                      [multiple]="false"
                      accept=".pdf,image/*"
                      uploadMode="instantly"
                      [uploadUrl]="backendURL + '/FileUpload/PostExpenses'"
                      (onValueChanged)="onValueChanged($event)"
                      (onUploaded)="onUploaded($event, data)"
                      (onUploadError)="onUploadError($event)">
    </dx-file-uploader>
    <dx-button class="retryButton" text="Retry" [visible]="retryButtonVisible" (onClick)="onClick($event)"></dx-button>
  </div>
  <div *dxTemplate="let data of 'noteTemplate'">
    <div class="multiline-note">{{ data.value }}</div>
  </div>
  <ng-container *dxTemplate="let spesa of 'dataRowTemplateName'">
    <tr class="main-row">
      <td>{{ spesa.data.data }}</td>
      <td>{{ spesa.data.expenseReasonId }}</td>
      <td>{{ spesa.data.importo }}</td>
      <td>{{ spesa.data.paymentTypeId }}</td>
      <td>{{ spesa.data.fattura}}</td>
      <td>{{ spesa.data.foto }}</td>
    </tr>
    <tr class="notes-row">
      <td colspan="6">
        <div>{{ spesa.data.note }}</div>
      </td>
    </tr>
  </ng-container>
</dx-data-grid>
<dx-speed-dial-action icon="add"
  label="Aggiungi"
  [index]="1"
  (onClick)="addRow()">
</dx-speed-dial-action>
<dx-speed-dial-action icon="trash"
  label="Cancella"
  [index]="2"
  [visible]="selectedRowIndex !== -1"
  (onClick)="deleteRow()">
</dx-speed-dial-action>
<dx-speed-dial-action icon="edit"
  label="Modifica"
  [index]="3"
  [visible]="selectedRowIndex !== -1"
  (onClick)="editRow()">
</dx-speed-dial-action>



