import { Component, ViewChild } from '@angular/core';
import { Subject, fromEvent, takeUntil } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CustomStore, createStore } from 'devextreme-aspnet-data-nojquery';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'app-appointment-dashboard-detail',
  templateUrl: './appointment-dashboard-detail.component.html',
  styleUrls: ['./appointment-dashboard-detail.component.scss']
})
export class AppointmentDashboardDetailComponent {
  @ViewChild('grid') grid!: DxDataGridComponent;

  private unsubscriber: Subject<void> = new Subject<void>();

  backendURL: string = environment.ApiUrl + '/api';
  gridDataSource: any;

  titolo = "";
  colAttivitaVisible = false;
  colInformatoreVisible = false;

  constructor(
    private toolbar: ToolbarService,
    private router: Router,
    private route: ActivatedRoute
  ) {

    
  }

  ngOnInit(): void {
    this.toolbar.TitleChanged("Dettaglio Appuntamenti");
    this.toolbar.CollapseVisibleChanged(false);
    this.toolbar.RefreshVisibleChanged(false);
    this.toolbar.BackVisibleChanged(true);
    this.toolbar.ChartVisibleChanged(false);

    this.toolbar.backClickedEvent.pipe(takeUntil(this.unsubscriber)).subscribe((_) => this.Back());

    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe(() => {
      history.pushState(null, '');

    });

    const currentUser = localStorage.getItem("userId");

    const informatore = this.route.snapshot.paramMap.get('informatore');

    const dateOptions: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
    };

    if (informatore) {
      this.colInformatoreVisible = false;
      this.colAttivitaVisible = true;

      const routeParts = informatore.split(';');
      const anno = routeParts[0];
      const mese = routeParts[1];
      const inf = routeParts[2];

      this.gridDataSource = createStore({
        key: 'pk',
        loadUrl: this.backendURL + '/AppointmentDashboard/GetDetailByIsf?areaManager=' + currentUser + '&anno=' + anno + '&mese=' + mese + '&informatore=' + inf,
      });

      const date = new Date(+anno, +mese - 1, 1);
      this.titolo = inf + " " + date.toLocaleString('it-IT', dateOptions );
    }

    const attivita = this.route.snapshot.paramMap.get('attivita');

    if (attivita) {
      this.colAttivitaVisible = false;
      this.colInformatoreVisible = true;

      const routeParts = attivita.split(';');
      const anno = routeParts[0];
      const mese = routeParts[1];
      const att = routeParts[2];

      this.gridDataSource = createStore({
        key: 'pk',
        loadUrl: this.backendURL + '/AppointmentDashboard/GetDetailByAttivita?areaManager=' + currentUser + '&anno=' + anno + '&mese=' + mese + '&attivita=' + att,
      });

      const date = new Date(+anno, +mese - 1, 1);
      this.titolo = att + " " + date.toLocaleString('it-IT', dateOptions);
    }

  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  customizeTotaleOre(cellInfo: { value: string; }) {
    if (cellInfo.value == "0") return "";
    return Number(cellInfo.value).toLocaleString("it-IT", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  }

  customizeTotaleGiorni(cellInfo: { value: string; }) {
    if (cellInfo.value == "0") return "";
    return Number(cellInfo.value).toLocaleString("it-IT", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  }

  Back(): void {
    this.router.navigate(['/appointment-dashboard']);
  }
}
