<dx-data-grid id="grid"
              [allowColumnReordering]="true"
              [columnAutoWidth]="true"
              [dataSource]="remoteDataSource"
              [repaintChangesOnly]="true"
              [wordWrapEnabled]="true"
              dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
              (onInitNewRow)="onInitNewRow($event)"
              (onRowDblClick)="onRowDblClick($event)"
              (onRowPrepared)="onRowPrepared($event)"
              (onSelectionChanged)="selectedChanged($event)"
              keyField="expenseId">
  <dxo-selection mode="single"></dxo-selection>
  <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
  <dxo-group-panel [visible]="isLuce"></dxo-group-panel>
  <dxo-header-filter [visible]="true">
    <dxo-search [enabled]="true"></dxo-search>
  </dxo-header-filter>
  <dxo-editing mode="popup">
    <dxo-popup title="Investimenti"
               [showTitle]="true">
    </dxo-popup>
    <dxo-form>
      <dxi-item dataField="dataRichiesta"
                [visible]="false">
      </dxi-item>
      <dxi-item dataField="dataInvestimento">
      </dxi-item>
      <dxi-item dataField="investmentTypeId"></dxi-item>
      <dxi-item dataField="note"
                editorType="dxTextArea"
                [colSpan]="2">
      </dxi-item>
      <dxi-item dataField="importo"></dxi-item>

      <dxi-item dataField="paymentTypeId"></dxi-item>
      <!--TODO: Fare venire fuori elenco agenti dell'AM da poter pescare (per campo informatore)-->
      <dxi-item dataField="informatore"
                [visible]="isAreaManager">
      </dxi-item>
      <dxi-item dataField="medici"
                editorType="dxTextArea"
                [visible]="!isLuce">
      </dxi-item>
      <dxi-item dataField="microaree"
                editorType="dxTextArea"
                [visible]="!isLuce">
      </dxi-item>
      <dxi-item dataField="investmentAuthorizationId"
                [visible]="isAreaManager && !isLuce">
      </dxi-item>
      <dxi-item dataField="investmentBudgetTypeId"
                [visible]="isAreaManager && !isLuce">
      </dxi-item>
      <dxi-item dataField="fattura"
                [visible]="!isLuce">
      </dxi-item>
      <dxi-item dataField="approvazioneAreaManager"
                [visible]="isAreaManager && !isLuce">
      </dxi-item>
      <dxi-item dataField="userName"
                [visible]="false">
      </dxi-item>
      <dxi-item dataField="foto"></dxi-item>
    </dxo-form>
  </dxo-editing>

  <dxo-filter-row [visible]="false"></dxo-filter-row>
  <dxo-search-panel [visible]="false"></dxo-search-panel>
  <dxi-column dataField="dataInvestimento"
              caption="Data Investimento"
              dataType="date"
              [hidingPriority]="7"
              [format]="{ type: 'shortDate' }">
    <dxi-validation-rule type="required">
    </dxi-validation-rule>
    <dxi-validation-rule type="range"
                         [min]="minDate"
                         [max]="maxDate"
                         message="La data deve essere compresa tra il mese scorso e l'anno prossimo">
    </dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="informatore"
              caption="Informatore"
              [groupIndex]="isAreaManager ? 1 : -1"
              calculateGroupValue="cognomeNomeInformatore"
              groupCellTemplate="informatoreGroupCellTemplate"
              [visible]="isAreaManager">
    @if (isAreaManager) {
    <dxi-validation-rule type="required"></dxi-validation-rule>
    }
    <dxo-lookup [dataSource]="informatoriDS"
                valueExpr="userId"
                displayExpr="nome"
                >
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="investmentTypeId"
              caption="Tipo. Inv.">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxo-lookup [dataSource]="investmentTypesDS"
                valueExpr="investmentTypeId"
                displayExpr="descrizione">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="note"
              dataType="string"
              caption="Note"
              [hidingPriority]="4">
  </dxi-column>
  <dxi-column dataField="medici"
              dataType="string"
              caption="Medici"
              [visible]="!isLuce"
              [hidingPriority]="5">
  </dxi-column>
  <dxi-column dataField="microaree"
              dataType="string"
              caption="Micro Aree"
              [visible]="!isLuce"
              [hidingPriority]="3">
  </dxi-column>
  <dxi-column dataField="investmentAuthorizationId"
              caption="Autorizzazione"
              [visible]="!isLuce"
              [hidingPriority]="2">
    <dxo-lookup [dataSource]="investmentAuthorizationDS"
                valueExpr="investmentAuthorizationId"
                displayExpr="descrizione">
    </dxo-lookup>
    @if (isAreaManager && !isLuce) {
    <dxi-validation-rule type="required">
    </dxi-validation-rule>
    }
  </dxi-column>
  <dxi-column dataField="importo"
              caption="Importo">
    <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="paymentTypeId"
              caption="Tipo. Pag."
              [hidingPriority]="1">
    @if (isAreaManager) {
    <dxi-validation-rule type="required">
    </dxi-validation-rule>
    }
    <dxo-lookup [dataSource]="paymentTypesDS"
                valueExpr="paymentTypeId"
                displayExpr="descrizione">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="investmentBudgetTypeId"
              caption="Tipo. Budget"
              [visible]="!isLuce"
              [hidingPriority]="0">
    <dxo-lookup [dataSource]="investmentBudgetTypesDS"
                valueExpr="investmentBudgetTypeId"
                displayExpr="descrizione">
    </dxo-lookup>
    @if (isAreaManager) {
    <dxi-validation-rule type="required">
    </dxi-validation-rule>
    }
  </dxi-column>
  <dxi-column dataField="foto"
              dataType="string"
              [visible]="false"
              [allowFiltering]="false"
              [allowSorting]="false"
              cellTemplate="cellTemplate"
              editCellTemplate="editCellTemplate">
  </dxi-column>
  <div *dxTemplate="let data of 'cellTemplate'">
    <img [src]="imageURL + data.value" />
  </div>
  <div *dxTemplate="let data of 'editCellTemplate'">
    @if (data.value && !(data.value | checkPdfExtension)) {
    <img #uploadedImage
         class="uploadedImage"
         [src]="imageURL + '/' + data.value" />
    }
    @if (data.value | checkPdfExtension) {
    <iframe class="uploadedImage"
            [src]="getPdfURL(data.value)">
    </iframe>
    }
    <dx-file-uploader #fileUploader
                      [multiple]="false"
                      accept=".pdf,image/*"
                      uploadMode="instantly"
                      [uploadUrl]="backendURL + '/FileUpload/PostInvestments'"
                      (onValueChanged)="onValueChanged($event)"
                      (onUploaded)="onUploaded($event, data)"
                      (onUploadError)="onUploadError($event)">
    </dx-file-uploader>
    <dx-button class="retryButton" text="Retry" [visible]="retryButtonVisible" (onClick)="onClick($event)"></dx-button>
  </div>

  <dxi-column dataField="userName"
              dataType="string"
              [visible]="false"
              [allowEditing]="false">
  </dxi-column>
  <dxi-column dataField="approvazioneAreaManager"
              dataType="boolean"
              [groupIndex]="0"
              groupCellTemplate="approvatoGroupCellTemplate"
              sortOrder="asc"
              caption="Approvato">
  </dxi-column>
  <dxi-column dataField="fattura"
              dataType="boolean"
              [visible]="false"
              caption="Fattura">
  </dxi-column>
  <dxo-summary>
    <dxi-group-item column="importo"
                    summaryType="sum"
                    [alignByColumn]="true"
                    [showInGroupFooter]="false"
                    [customizeText]="customizeTotaleImporto">
    </dxi-group-item>
  </dxo-summary>

  <div *dxTemplate="let data of 'approvatoGroupCellTemplate'">
    <div>{{(data.value === true ? 'Approvato' : 'Non Approvato')}}</div>
  </div>

  <div *dxTemplate="let data of 'informatoreGroupCellTemplate'">
    <div>{{data.text}}</div>
  </div>
</dx-data-grid>
<dx-speed-dial-action icon="add"
  label="Aggiungi"
  [index]="1"
  (onClick)="addRow()">
</dx-speed-dial-action>
<dx-speed-dial-action icon="trash"
  label="Cancella"
  [index]="2"
  [visible]="selectedRowIndex !== -1 && modificabile"
  (onClick)="deleteRow()">
</dx-speed-dial-action>
<dx-speed-dial-action icon="edit"
  label="Modifica"
  [index]="3"
  [visible]="selectedRowIndex !== -1 && modificabile"
  (onClick)="editRow()">
</dx-speed-dial-action>
<dx-speed-dial-action icon="todo"
  label="Approva"
  [index]="4"
  [visible]="selectedRowIndex !== -1 && isAreaManager  && modificabile  && completo"
  (onClick)="approvaRow()">
</dx-speed-dial-action>
