<div class="dx-card responsive-paddings">
  <div class="button-toolbar">
    <dx-date-box [(value)]="inizio"
                 type="date"
                 label="Inizio"
                 labelMode="floating"
                 [width]="134"
                 dateSerializationFormat="yyyy-MM-dd">

    </dx-date-box>
  </div>
  <div class="button-toolbar">
    <dx-date-box [(value)]="fine"
                 type="date"
                 label="Fine"
                 labelMode="floating"
                 [width]="134"
                 dateSerializationFormat="yyyy-MM-dd">

    </dx-date-box>
  </div>
  <div class="button-toolbar">
    <dx-button stylingMode="contained"
               type="success"
               icon="search"
               (onClick)="searchClick()">
    </dx-button>
  </div>
</div>

<h2 Id="ActivityTitle" class="content-block">Per Attività</h2>
<div class="content-block dx-card wide-card responsive-paddings">
  <dx-data-grid #gridAttivita
                [allowColumnReordering]="true"
                [columnAutoWidth]="true"
                [dataSource]="attivitaDataSource"
                [repaintChangesOnly]="true"
                (onRowDblClick)="onAttivitaRowDblClick($event)"
                keyField="pk">
    <dxo-selection mode="single"></dxo-selection>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
    <dxo-editing [allowUpdating]="false"
                 [allowDeleting]="false"
                 [allowAdding]="false">
    </dxo-editing>
    <dxo-filter-row [visible]="false"></dxo-filter-row>
    <dxo-search-panel [visible]="false"></dxo-search-panel>
    <dxi-column dataField="data"
                sortOrder="desc"
                [groupIndex]="0"
                groupCellTemplate="groupCellTemplate">
    </dxi-column>
    <dxi-column dataField="attivita"
                dataType="string"
                cellTemplate="attivitaTemplate"
                caption="Attività">
    </dxi-column>
    <dxi-column dataField="ore">
      <dxo-format type="fixedPoint" [precision]="1"></dxo-format>
    </dxi-column>
    <dxi-column dataField="giorni">
      <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
    </dxi-column>
    <dxi-column type="buttons" [width]="35">
      <dxi-button hint="Dettagli"
                  icon="info"
                  [onClick]="infoAttivitaClick">
      </dxi-button>
    </dxi-column>
    <dxo-summary>
      <dxi-group-item column="ore"
                      summaryType="sum"
                      [showInGroupFooter]="false"
                      [customizeText]="customizeTotaleOre"
                      [alignByColumn]="true">
      </dxi-group-item>
      <dxi-group-item column="giorni"
                      summaryType="sum"
                      [customizeText]="customizeTotaleGiorni"
                      [showInGroupFooter]="false"
                      [alignByColumn]="true">
      </dxi-group-item>
    </dxo-summary>

    <div *dxTemplate="let data of 'attivitaTemplate'">
      <div class="multiline-note">{{ data.value }}</div>
    </div>

    <div *dxTemplate="let data of 'groupCellTemplate'">
      <div>{{data.value.split(';')[1]}}</div>
    </div>

  </dx-data-grid>
</div>
<div class="content-block dx-card wide-card responsive-paddings">
  <dx-pie-chart id="pieAttivita"
                palette="bright"
                resolveLabelOverlapping="shift"
                [dataSource]="attivitaChartDataSource">
    <dxi-series argumentField="attivita" valueField="ore">
      <dxo-label [visible]="true"
                 displayFormat="{argumentText}">
        <dxo-connector [visible]="true" [width]="1"></dxo-connector>
      </dxo-label>
      <dxo-aggregation [enabled]="true"
                       method="sum">
      </dxo-aggregation>
    </dxi-series>
    <dxo-legend [visible]="false">
    </dxo-legend>
  </dx-pie-chart>
</div>
  <h2 Id="IsfTitle" class="content-block">Per Informatore</h2>
  <div class="content-block dx-card wide-card responsive-paddings">
    <dx-data-grid #gridInformatore
                  [allowColumnReordering]="true"
                  [columnAutoWidth]="true"
                  [dataSource]="informatoreDataSource"
                  [repaintChangesOnly]="true"
                  (onRowDblClick)="onInformatoreRowDblClick($event)"
                  keyField="pk">
      <dxo-selection mode="single"></dxo-selection>
      <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
      <dxo-editing [allowUpdating]="false"
                   [allowDeleting]="false"
                   [allowAdding]="false">
      </dxo-editing>
      <dxo-filter-row [visible]="false"></dxo-filter-row>
      <dxo-search-panel [visible]="false"></dxo-search-panel>
      <dxi-column dataField="data"
                  sortOrder="desc"
                  [groupIndex]="0"
                  groupCellTemplate="groupCellTemplate">
      </dxi-column>
      <dxi-column dataField="informatore"
                  dataType="string"
                  cellTemplate="informatoreTemplate"
                  caption="Attività">
      </dxi-column>
      <dxi-column dataField="ore">
        <dxo-format type="fixedPoint" [precision]="1"></dxo-format>
      </dxi-column>
      <dxi-column dataField="giorni">
        <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
      </dxi-column>
      <dxi-column type="buttons" [width]="35">
        <dxi-button hint="Dettagli"
                    icon="info"
                    [onClick]="infoInformatoreClick">
        </dxi-button>
      </dxi-column>
      <dxo-summary>
        <dxi-group-item column="ore"
                        summaryType="sum"
                        [showInGroupFooter]="false"
                        [customizeText]="customizeTotaleOre"
                        [alignByColumn]="true">
        </dxi-group-item>
        <dxi-group-item column="giorni"
                        summaryType="sum"
                        [customizeText]="customizeTotaleGiorni"
                        [showInGroupFooter]="false"
                        [alignByColumn]="true">
        </dxi-group-item>
      </dxo-summary>

      <div *dxTemplate="let data of 'informatoreTemplate'">
        <div class="multiline-note">{{ data.value }}</div>
      </div>

      <div *dxTemplate="let data of 'groupCellTemplate'">
        <div>{{data.value.split(';')[1]}}</div>
      </div>

    </dx-data-grid>
  </div>
  <div class="content-block dx-card wide-card responsive-paddings">
    <dx-pie-chart id="pieInformatori"
                  palette="bright"
                  resolveLabelOverlapping="shift"
                  [dataSource]="informatoreChartDataSource">
      <dxi-series argumentField="informatore" valueField="ore">
        <dxo-label [visible]="true"
                   displayFormat="{argumentText}">
          <dxo-connector [visible]="true" [width]="1"></dxo-connector>
        </dxo-label>
        <dxo-aggregation [enabled]="true"
                         method="sum">
        </dxo-aggregation>
      </dxi-series>
      <dxo-legend [visible]="false">
      </dxo-legend>
    </dx-pie-chart>
  </div>
