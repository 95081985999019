import { Injectable } from '@angular/core';
import { investmentRequestsDb } from '../../shared/models/db'
import { InvestmentRequest } from '../models/investment-request';
import { PromiseExtended } from 'dexie';

@Injectable({
  providedIn: 'root'
})
export class InvestmentRequestsDbService {
  getInvestmentRequest(investmentRequestId: number): Promise<InvestmentRequest | undefined> {

    const investmentRequestJson = localStorage.getItem("investmentRequest") ?? "";

    const safeJsonParse = <T>(str: string) => {
      try {
        const jsonValue: T = JSON.parse(str);

        return jsonValue;
      } catch {
        return undefined;
      }
    };

    const request = safeJsonParse<InvestmentRequest>(investmentRequestJson);

    if (request) {
      return new Promise(resolve => {
        resolve(request);
      });
    }
    else {
      return new Promise(resolve => {
        resolve(undefined);
      });
    }

  }

  addInvestmentRequest(investmentRequest: InvestmentRequest): Promise<number> {

    localStorage.setItem("investmentRequest", JSON.stringify(investmentRequest));

    return new Promise(resolve => {
      resolve(1);
    });
  }

  updateInvestmentRequest(investmentRequest: InvestmentRequest): Promise<number> {
    localStorage.setItem("investmentRequest", JSON.stringify(investmentRequest));

    return new Promise(resolve => {
      resolve(1);
    });
  }
  constructor() { }


  
}
