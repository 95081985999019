import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginFormComponent } from './shared/components';
import { AuthGuardService } from './shared/services';
import { ExpensesComponent } from './pages/expenses/expenses.component';
import { InvestmentsComponent } from './pages/investments/investments.component';
import { AppointmentsComponent } from './pages/appointments/appointments.component';
import { DxButtonModule, DxDataGridModule, DxDateBoxModule, DxDropDownBoxModule, DxFileUploaderModule, DxFormModule, DxPopupModule, DxSchedulerModule, DxSelectBoxModule, DxSpeedDialActionModule, DxTextAreaModule, DxChartModule, DxPieChartModule, DxLookupModule } from 'devextreme-angular';
import { AppointmentDashboardComponent } from './pages/appointment-dashboard/appointment-dashboard.component';
import { AppointmentDashboardDetailComponent } from './pages/appointment-dashboard-detail/appointment-dashboard-detail.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CheckPdfExtensionPipe } from './shared/pipes/check-pdf-extension.pipe';
import { InvestmentRequestComponent } from './pages/investment-request/investment-request.component';
import { InvestmentRequestsComponent } from './pages/investment-requests/investment-requests.component';
import { InvestmentRequestDoctorComponent } from './pages/investment-request-doctor/investment-request-doctor.component';
import { DoctorsComponent } from './pages/doctors/doctors.component';

const routes: Routes = [
  {
    path: 'expenses',
    component: ExpensesComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'isRight' }
  },
  {
    path: 'investments',
    component: InvestmentsComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'isLeft' }
  },
  {
    path: 'appointments',
    component: AppointmentsComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'isRight' }
  },
  {
    path: 'appointment-dashboard',
    component: AppointmentDashboardComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'isRight' }
  },
  {
    path: 'appointment-dashboard-detail',
    component: AppointmentDashboardDetailComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'isRight' }
  },
  {
    path: 'doctors',
    component: DoctorsComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'isRight' }
  },
  {
    path: 'investment-request',
    component: InvestmentRequestComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'isRight' }
  },
  {
    path: 'investment-requests',
    component: InvestmentRequestsComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'isRight' }
  },
  {
    path: 'investment-request-doctor',
    component: InvestmentRequestDoctorComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'isRight' }
  },
  {
    path: 'login-form',
    component: LoginFormComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'isLeft' }
  },
  {
    path: '**',
    redirectTo: 'investments'
  }
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { useHash: true }),
    DxDataGridModule,
    DxSpeedDialActionModule,
    DxFormModule,
    DxChartModule,
    DxPieChartModule,
    DxDateBoxModule,
    DxSchedulerModule,
    DxFileUploaderModule,
    DxLookupModule,
    DxButtonModule,
    DxPopupModule,
    DxFormModule,
    DxDropDownBoxModule,
    DxSelectBoxModule,
    DxTextAreaModule,
  ],
  providers: [AuthGuardService],
  exports: [RouterModule],
  declarations: [
    ExpensesComponent,
    InvestmentsComponent,
    InvestmentRequestComponent,
    InvestmentRequestsComponent,
    InvestmentRequestDoctorComponent,
    AppointmentsComponent,
    AppointmentDashboardComponent,
    AppointmentDashboardDetailComponent,
    DoctorsComponent,
    CheckPdfExtensionPipe]
})
export class AppRoutingModule { }
