<div class="content-block dx-card responsive-paddings">
  <dx-form id="form"
           labelLocation="left"
           labelMode="floating"
           [formData]="investmentRequest"
           [colCount]="2"
           (onFieldDataChanged)="onFieldDataChanged($event)"
           [readOnly]="isFormReadOnly"
           [showValidationSummary]="true"
           validationGroup="registrationData">
    <dxi-item dataField="investmentRequestTypeId"
              editorType="dxLookup"
              [isRequired]="true"
              [editorOptions]="investmentRequestTypeEditorOptions"
              [colSpan]="2">
      <dxo-label text="Causale"></dxo-label>
      <dxi-validation-rule type="required"></dxi-validation-rule>

    </dxi-item>
    @if ((isAreaManager || isAdministrator) && currentInvestmentRequestKind !== null) {
    <dxi-item dataField="investmentRequestStateId"
              editorType="dxLookup"
              [editorOptions]="investmentRequestStatesEditorOptions"
              [colSpan]="2">
      <dxo-label text="Stato"> </dxo-label>
      <dxi-validation-rule type="required"
                           message="Lo stato è richiesto">
      </dxi-validation-rule>
    </dxi-item>
    }
    @if (currentInvestmentRequestKind !== null)
    {
    @if (
    currentInvestmentRequestKind !== allInvestmentRequestKinds.BuoniCarburante &&
    currentInvestmentRequestKind !== allInvestmentRequestKinds.BuoniSpesa &&
    currentInvestmentRequestKind !== allInvestmentRequestKinds.GrantMedici &&
    currentInvestmentRequestKind !== allInvestmentRequestKinds.MaterialeElettronico &&
    currentInvestmentRequestKind !== allInvestmentRequestKinds.MaterialeSanitario &&
    currentInvestmentRequestKind !== allInvestmentRequestKinds.VisiteAziendali &&
    currentInvestmentRequestKind !== allInvestmentRequestKinds.Viaggi
    )
    {
    <dxi-item dataField="dataInvestimento"
              editorType="dxDateBox"
              [editorOptions]="dataEditorOptions"
              [isRequired]="true"
              [colSpan]="2">
      @if (
      currentInvestmentRequestKind === allInvestmentRequestKinds.IscrizioneCongressi ||
      currentInvestmentRequestKind === allInvestmentRequestKinds.SponsorizzazioneCongressi  )
      {
      <dxo-label text="Data Inizio Congresso"> </dxo-label>
      }
      @else if(currentInvestmentRequestKind === allInvestmentRequestKinds.Cene)
      {
      <dxo-label text="Data Cena"></dxo-label>
      }
      @else
      {
      <dxo-label text="Data Investimento"> </dxo-label>
      }
      <dxi-validation-rule type="required"
                           message="La data investimento è richiesta">
      </dxi-validation-rule>
      <dxi-validation-rule type="range"
                           [min]="minDate"
                           [max]="maxDate"
                           message="La data deve essere compresa tra il mese scorso e l'anno prossimo">
      </dxi-validation-rule>
    </dxi-item>
    }
    <dxi-item dataField="informatoreId"
              editorType="dxLookup"
              [visible]="isAreaManager"
              [editorOptions]="informatoreEditorOptions"
              [colSpan]="2">
      <dxo-label text="Informatore"> </dxo-label>
      @if (isAreaManager) {
      <dxi-validation-rule type="required"
                           message="L'informatore è richiesto">
      </dxi-validation-rule>
      }
    </dxi-item>
    <dxi-item dataField="note"
              editorType="dxTextArea"
              [editorOptions]="notesEditorOptions"
              [colSpan]="2">
      <dxo-label text="Note"></dxo-label>
    </dxi-item>
    <dxi-item dataField="investmentBudgetTypeId"
              editorType="dxLookup"
              [editorOptions]="investmentBudgetTypeEditorOptions"
              [colSpan]="2">
      <dxo-label text="Tipo Budget"></dxo-label>
    </dxi-item>
    <dxi-item dataField="importo"
              editorType="dxNumberBox"
              [editorOptions]="importoEditorOptions"
              [isRequired]="currentInvestmentRequestKind !==  allInvestmentRequestKinds.MaterialeElettronico && currentInvestmentRequestKind !== allInvestmentRequestKinds.MaterialeSanitario  && currentInvestmentRequestKind !== allInvestmentRequestKinds.VisiteAziendali && currentInvestmentRequestKind !== allInvestmentRequestKinds.IscrizioneCongressi && currentInvestmentRequestKind !== allInvestmentRequestKinds.Viaggi"
              [colSpan]="2">

      @if(currentInvestmentRequestKind === allInvestmentRequestKinds.SponsorizzazioneCongressi)
      {
      <dxo-label text="Importo Sponsorizzazione"></dxo-label>
      }
      @else if(currentInvestmentRequestKind === allInvestmentRequestKinds.Cene)
      {
      <dxo-label text="Importo Cena"></dxo-label>
      }
      @else
      {
      <dxo-label text="Importo"></dxo-label>
      }

      @if (
      currentInvestmentRequestKind !== allInvestmentRequestKinds.MaterialeElettronico &&
      currentInvestmentRequestKind !== allInvestmentRequestKinds.MaterialeSanitario &&
      currentInvestmentRequestKind !== allInvestmentRequestKinds.VisiteAziendali &&
      currentInvestmentRequestKind !== allInvestmentRequestKinds.IscrizioneCongressi &&
      currentInvestmentRequestKind !== allInvestmentRequestKinds.Viaggi )
      {
      <dxi-validation-rule type="range"
                           [min]="minImporto"
                           [max]="maxImporto"
                           message="L'importo deve essere maggiore di 0">
      </dxi-validation-rule>
      }
      @if (currentInvestmentRequestKind === allInvestmentRequestKinds.BuoniCarburante)
      {
      <dxi-validation-rule type="custom"
                           [validationCallback]="validateBuonoCarburante"
                           message="L'importo deve essere un multiplo di 50 Euro'">
      </dxi-validation-rule>
      }
    </dxi-item>
    }
    @if(currentInvestmentRequestKind === allInvestmentRequestKinds.Cene) {
    <dxi-item itemType="group"
              caption="Cena"
              [colSpan]="2">
      <dxi-item dataField="restaurantName"
                editorType="dxTextBox"
                [isRequired]="true"
                [colSpan]="2">
        <dxo-label text="Nome Ristorante"></dxo-label>

      </dxi-item>
      <dxi-item dataField="restaurantPlace"
                editorType="dxTextBox"
                [isRequired]="true"
                [colSpan]="2">
        <dxo-label text="Località Ristorante"></dxo-label>
      </dxi-item>
      <dxi-item dataField="restaurantMail"
                editorType="dxTextBox"
                [editorOptions]="restaurantMailEditorOption"
                [isRequired]="true"
                [colSpan]="2">
        <dxo-label text="E-Mail Ristorante"></dxo-label>
        <dxi-validation-rule type="email"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="restaurantPhone"
                editorType="dxTextBox"
                [editorOptions]="restaurantPhoneEditorOption"
                [isRequired]="true"
                [colSpan]="2">
        <dxo-label text="Telefono Ristorante"></dxo-label>
      </dxi-item>
      <dxi-item dataField="restaurantAttendees"
                editorType="dxNumberBox"
                [editorOptions]="positiveIntegerEditorOptions"
                [isRequired]="true"
                [colSpan]="2">
        <dxo-label text="Numero di partecipanti"></dxo-label>
      </dxi-item>
    </dxi-item>
    }
    @if(currentInvestmentRequestKind === allInvestmentRequestKinds.MaterialeElettronico) {
    <dxi-item itemType="group"
              caption="Materiale Elettronico"
              [colSpan]="2">
      <dxi-item dataField="materialDescription"
                editorType="dxTextArea"
                [editorOptions]="notesEditorOptions"
                [isRequired]="true"
                [colSpan]="2">
        <dxo-label text="Descrizione Materiale"></dxo-label>
      </dxi-item>
    </dxi-item>
    }
    @if(currentInvestmentRequestKind === allInvestmentRequestKinds.IscrizioneCongressi) {
    <dxi-item itemType="group"
              caption="Iscrizione Congressi"
              [colSpan]="2">
      <dxi-item dataField="conventionRegistrationName"
                editorType="dxTextBox"
                [isRequired]="true"
                [colSpan]="2">
        <dxo-label text="Nome del Congresso"></dxo-label>
      </dxi-item>
      <dxi-item dataField="conventionRegistrationDistrictId"
                editorType="dxLookup"
                [editorOptions]="conventionRegistrationDistrictEditorOptions"
                [isRequired]="true"
                [colSpan]="2">
        <dxo-label text="Luogo del congresso"></dxo-label>
      </dxi-item>
    </dxi-item>
    }
    @if(currentInvestmentRequestKind === allInvestmentRequestKinds.SponsorizzazioneCongressi) {
    <dxi-item itemType="group"
              caption="Sponsorizzazione Congressi"
              [colSpan]="2">
      <dxi-item dataField="conventionRegistrationName"
                editorType="dxTextBox"
                [isRequired]="true"
                [colSpan]="2">
        <dxo-label text="Nome del Congresso"></dxo-label>
      </dxi-item>
      <dxi-item dataField="conventionRegistrationDistrictId"
                editorType="dxLookup"
                [editorOptions]="conventionRegistrationDistrictEditorOptions"
                [isRequired]="true"
                [colSpan]="2">
        <dxo-label text="Luogo del congresso"></dxo-label>
      </dxi-item>
      <dxi-item dataField="sponsorshipExhibition"
                editorType="dxCheckBox">
        <dxo-label text="Affitto spazio espositivo"></dxo-label>
      </dxi-item>
    </dxi-item>
    }
    @if(currentInvestmentRequestKind === allInvestmentRequestKinds.Viaggi) {
    <dxi-item itemType="group"
              caption="Viaggi"
              [colSpan]="2">
      <dxi-item dataField="travelDestination"
                editorType="dxTextBox"
                [isRequired]="true"
                [colSpan]="2">
        <dxo-label text="Destinazione"></dxo-label>
      </dxi-item>
      <dxi-item dataField="travelCheckIn"
                editorType="dxDateBox"
                [editorOptions]="dataEditorOptions"
                [isRequired]="true"
                [colSpan]="2">
        <dxo-label text="Check-In"></dxo-label>
      </dxi-item>
      <dxi-item dataField="travelCheckOut"
                editorType="dxDateBox"
                [editorOptions]="dataEditorOptions"
                [colSpan]="2">
        <dxo-label text="Check-Out"></dxo-label>
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxi-validation-rule type="compare"
                             [comparisonTarget]="travelCheckInComparison"
                             comparisonType=">="
                             message="La data deve essere uguale o successiva a quella di Check IN">

        </dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="travelTreatment"
                editorType="dxSelectBox"
                [editorOptions]="travelTreatmentEditorOptions"
                [isRequired]="currenttravelCheckIn !== currenttravelCheckOut"
                [colSpan]="2">
        <dxo-label text="Trattamento"></dxo-label>
      </dxi-item>
      <dxi-item dataField="travelRoom"
                editorType="dxSelectBox"
                [editorOptions]="travelRoomEditorOptions"
                [isRequired]="currenttravelCheckIn !== currenttravelCheckOut"
                [colSpan]="2">
        <dxo-label text="Tipologia camera"></dxo-label>
      </dxi-item>
    </dxi-item>
    }
    @if(currentInvestmentRequestKind === allInvestmentRequestKinds.VisiteAziendali) {
    <dxi-item itemType="group"
              caption="Visita Aziendale"
              [colSpan]="2">
      <dxi-item dataField="companyVisitDate"
                editorType="dxDateBox"
                [editorOptions]="dataEditorOptions"
                [isRequired]="true"
                [colSpan]="2">
        <dxo-label text="Data Visita reparto produttivo"> </dxo-label>
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxi-validation-rule type="range"
                             [min]="minDate"
                             [max]="maxDate"
                             message="La data deve essere compresa tra il mese scorso e l'anno prossimo">
        </dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="companyVisitCheckIn"
                editorType="dxDateBox"
                [editorOptions]="dataEditorOptions"
                [isRequired]="true"
                [colSpan]="2">
        <dxo-label text="Check IN"> </dxo-label>
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxi-validation-rule type="range"
                             [min]="minDate"
                             [max]="maxDate"
                             message="La data deve essere compresa tra il mese scorso e l'anno prossimo">
        </dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="companyVisitCheckOut"
                editorType="dxDateBox"
                [editorOptions]="dataEditorOptions"
                [isRequired]="true"
                [colSpan]="2">
        <dxo-label text="Check OUT"> </dxo-label>
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxi-validation-rule type="range"
                             [min]="minDate"
                             [max]="maxDate"
                             message="La data deve essere compresa tra il mese scorso e l'anno prossimo">
        </dxi-validation-rule>
        <dxi-validation-rule type="compare"
                             [comparisonTarget]="companyVisitCheckInComparison"
                             comparisonType=">="
                             message="La data deve essere uguale o successiva a quella di Check IN">

        </dxi-validation-rule>
      </dxi-item>
    </dxi-item>
    }
    @if(currentInvestmentRequestKind === allInvestmentRequestKinds.MaterialeSanitario) {
    <dxi-item itemType="group"
              caption="Materiale Sanitario"
              [colSpan]="2">
      <dxi-item dataField="materialDescription"
                editorType="dxTextArea"
                [editorOptions]="notesEditorOptions"
                [isRequired]="true"
                [colSpan]="2">
        <dxo-label text="Descrizione Materiale"></dxo-label>
      </dxi-item>
      <dxi-item dataField="sanitaryMaterialMedicalObject"
                editorType="dxCheckBox">
        <dxo-label text="Oggetto Medico"></dxo-label>
      </dxi-item>
      <dxi-item dataField="sanitaryMaterialPrescriptionPad"
                editorType="dxCheckBox">
        <dxo-label text="Ricettari Medici"></dxo-label>
      </dxi-item>
      <dxi-item dataField="sanitaryMaterialPriceList"
                editorType="dxCheckBox">
        <dxo-label text="Listino Personalizzato">
        </dxo-label>
      </dxi-item>
    </dxi-item>
    }
    @if(currentInvestmentRequestKind === allInvestmentRequestKinds.BuoniSpesa) {
    <dxi-item itemType="group"
              caption="Buoni Spesa"
              [colSpan]="2">
      <dxi-item dataField="investmentRequestVoucherTypeId"
                editorType="dxLookup"
                [isRequired]="true"
                [editorOptions]="investmentRequestVoucherTypeEditorOptions"
                [colSpan]="2">
        <dxo-label text="Tipo di Buoni"></dxo-label>
        <dxi-validation-rule type="required"></dxi-validation-rule>

      </dxi-item>
      <dxi-item dataField="voucherCount"
                editorType="dxNumberBox"
                [editorOptions]="positiveIntegerEditorOptions"
                [isRequired]="true"
                [colSpan]="2">
        <dxo-label text="Numero di buoni"></dxo-label>
      </dxi-item>
    </dxi-item>
    }

    @if(!isFormReadOnly && currentInvestmentRequestKind !== null) {

    <dxi-item itemType="button"
              [colSpan]="1"
              horizontalAlignment="center"
              [buttonOptions]="aggiungiMedicoButtonOptions">
    </dxi-item>

    <dxi-item itemType="button"
              [colSpan]="1"
              horizontalAlignment="center"
              [buttonOptions]="submitButtonOptions">
    </dxi-item>
    }
  </dx-form>
</div>
@if(currentInvestmentRequestKind !== null) {
<div class="content-block dx-card responsive-paddings">

  <dx-data-grid id="gridContainer"
                [dataSource]="investmentRequest.investmentRequestDoctors"
                keyExpr="investmentRequestDoctorId"
                [showBorders]="false"
                [rowAlternationEnabled]="true"
                noDataText="Nessun medico inserito"
                [hoverStateEnabled]="true">
    <dxo-editing [allowDeleting]="!isFormReadOnly">
    </dxo-editing>
    <dxi-column dataField="surname"
                caption="Cognome">
    </dxi-column>
    <dxi-column dataField="name"
                caption="Nome">
    </dxi-column>
    <dxi-column type="buttons">
      <dxi-button icon="edit"
                  hint="Modifica"
                  [onClick]="onEditButtonClick">
      </dxi-button>
      <dxi-button name="delete"></dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>
}
