import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

export interface UserForAuthenticationDto {
  Username: string;
  Password: string;
}

export interface AuthResponseDto {
  isAuthSuccessful: boolean;
  errorMessage: string;
  token: string;
  isAreaManager: boolean;
  isNoteSpese: boolean;
  isAdministrator: boolean
  isDipendente: boolean
  userId: string;
  nomeUtente: string;
}

const defaultPath = '/';


@Injectable()
export class AuthService {
  private _user: UserForAuthenticationDto | null = null;
  private _errorMessage: string | null = null;

  get loggedIn(): boolean {
    const token = localStorage.getItem("jwt");
    let user = localStorage.getItem("user");

    user ??= "Erbozeta";

    //if (user) {
      this._user ??= {
        Username: user,
        Password: ""
      };

    //}

    if (this._user) {
      if (token && !this.jwtHelper.isTokenExpired(token)) return true;
    }

    return false;
  }

  get errorMessage(): string | null {
    return this._errorMessage;
  }

  private _lastAuthenticatedPath: string = defaultPath;

  set lastAuthenticatedPath(value: string) {
    if (this._lastAuthenticatedPath === '/login-form') this._lastAuthenticatedPath = '/';
    this._lastAuthenticatedPath = value;
  }

  constructor(private router: Router, private http: HttpClient, private jwtHelper: JwtHelperService) { }

  logIn(email: string, password: string): Observable<AuthResponseDto> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    const body: UserForAuthenticationDto = {
      Username: email,
      Password: password
    }


    return this.http.post<AuthResponseDto>(environment.ApiUrl + '/api/Accounts/Login', body, httpOptions);
  }

  async logOut() {
    this._user = null;
    localStorage.removeItem("jwt");
    localStorage.removeItem("isAreaManager");
    localStorage.removeItem("isNoteSpese");
    localStorage.removeItem("isDipendente");
    localStorage.removeItem("userId");
    localStorage.removeItem("nomeUtente");

    this.router.navigate(['/login-form']);
  }
}

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private jwtHelper: JwtHelperService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isLoggedIn = this.authService.loggedIn;

    let isAreaManager = false;
    let isNoteSpese = false;
    let isDipendente = false;

    const isAuthForm = [
      'login-form'
    ].includes(route.routeConfig?.path || defaultPath);

    if (isLoggedIn && isAuthForm) {
      this.authService.lastAuthenticatedPath = defaultPath;
      this.router.navigate([defaultPath]);
      return false;
    }

    if (!isLoggedIn && !isAuthForm) {
      this.router.navigate(['/login-form']);
    }

    if (localStorage.getItem("isAreaManager") == "S") {
      isAreaManager = true;
    }

    if (localStorage.getItem("isNoteSpese") == "S") {
      isNoteSpese = true;
    }

    if (localStorage.getItem("isDipendente") == "S") {
      isDipendente = true;
    }

    if (isDipendente) {
      const isExpensesForm = [
        'expenses'
      ].includes(route.routeConfig?.path || defaultPath);

      if (!isExpensesForm) {
        this.router.navigate(['/expenses']);
      }
    }

    if (isLoggedIn) {
      this.authService.lastAuthenticatedPath = route.routeConfig?.path || defaultPath;
    }

    if (route.routeConfig?.path === "appointments") return isAreaManager;
    if (route.routeConfig?.path === "appointment-dashboard") return isAreaManager;
    if (route.routeConfig?.path === "appointment-dashboard-detail") return isAreaManager;
    if (route.routeConfig?.path === "expenses") return isNoteSpese || isDipendente;

    return isLoggedIn || isAuthForm;
  }
}
