<dx-data-grid #grid
              [allowColumnReordering]="true"
              [columnAutoWidth]="true"
              [dataSource]="remoteDataSource"
              [repaintChangesOnly]="true"
              [wordWrapEnabled]="true"
              (onRowDblClick)="onRowDblClick($event)"
              (onSelectionChanged)="selectedChanged($event)"
              (onInitNewRow)="onInitNewRow($event)"
              (onEditorPreparing)="onEditorPreparing($event)"
              keyField="doctorId">
  <dxo-selection mode="single"></dxo-selection>
  <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
  <dxo-editing mode="popup">
    <dxo-popup title="Medico"
               [showTitle]="true">
    </dxo-popup>
    <dxo-form>
      <dxi-item dataField="surname"></dxi-item>
      <dxi-item dataField="name"></dxi-item>
      <dxi-item dataField="investmentRequestSpecializationId"></dxi-item>
      <dxi-item dataField="workPlaceId"></dxi-item>
      <dxi-item dataField="workPlaceAddress"></dxi-item>
      <dxi-item dataField="email"></dxi-item>
      <dxi-item dataField="phone"></dxi-item>
      <dxi-item dataField="microArea"></dxi-item>
      <dxi-item dataField="informatoreId"></dxi-item>
      <dxi-item dataField="userName"
                [visible]="false">
      </dxi-item>
    </dxo-form>
  </dxo-editing>
  <dxo-filter-row [visible]="false"></dxo-filter-row>
  <dxo-header-filter [visible]="true">
    <dxo-search [enabled]="true"></dxo-search>
  </dxo-header-filter>
  <dxo-search-panel [visible]="false"></dxo-search-panel>
  <dxi-column dataField="medico"
              dataType="string"
              caption="Medico">
  </dxi-column>
  <dxi-column dataField="surname"
              dataType="string"
              [visible]="false"
              caption="Cognome">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="name"
              dataType="string"
              [visible]="false"
              caption="Nome">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="investmentRequestSpecializationId"
              caption="Specializzazione">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxo-lookup [dataSource]="investmentRequestSpecializationDS"
                valueExpr="couponSpecializationId"
                displayExpr="descrizione">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="provincia"
              dataType="string"
              caption="Prov">
  </dxi-column>
  <dxi-column dataField="workPlaceId"
              caption="Comune di Lavoro">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxo-lookup [dataSource]="workPlaceDS"
                valueExpr="districtId"
                displayExpr="descrizione">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="workPlaceAddress"
              dataType="string"
              caption="Indirizzo">
  </dxi-column>
  <dxi-column dataField="email"
              dataType="string"
              caption="E-mail">
  </dxi-column>
  <dxi-column dataField="phone"
              dataType="string"
              caption="Telefono">
  </dxi-column>
  <dxi-column dataField="microArea"
              dataType="string"
              caption="MicroArea">
  </dxi-column>
  <dxi-column dataField="informatoreId"
              caption="Informatore">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxo-lookup [dataSource]="informatoriDS"
                valueExpr="userId"
                displayExpr="nome">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="userName"
              dataType="string"
              [visible]="false"
              [allowEditing]="false">
  </dxi-column>
</dx-data-grid>
<dx-speed-dial-action icon="add"
                      label="Aggiungi"
                      [index]="1"
                      (onClick)="addRow()">
</dx-speed-dial-action>
<dx-speed-dial-action icon="trash"
                      label="Cancella"
                      [index]="2"
                      [visible]="selectedRowIndex !== -1"
                      (onClick)="deleteRow()">
</dx-speed-dial-action>
<dx-speed-dial-action icon="edit"
                      label="Modifica"
                      [index]="3"
                      [visible]="selectedRowIndex !== -1"
                      (onClick)="editRow()">
</dx-speed-dial-action>




