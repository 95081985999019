<h2 Id="DetailTitle" class="content-block">{{titolo}}</h2>
<div class="content-block dx-card wide-card responsive-paddings">
  <dx-data-grid #grid
                [allowColumnReordering]="true"
                [columnAutoWidth]="true"
                [dataSource]="gridDataSource"
                [repaintChangesOnly]="true"
                keyField="pk">
    <dxo-selection mode="single"></dxo-selection>
    <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
    <dxo-editing [allowUpdating]="false"
                 [allowDeleting]="false"
                 [allowAdding]="false">
    </dxo-editing>
    <dxo-filter-row [visible]="false"></dxo-filter-row>
    <dxo-search-panel [visible]="false"></dxo-search-panel>
    <dxi-column dataField="data"
                dataType="date"
                sortOrder="asc"
                [format]="{ type: 'dd/MM/yyyy' }">
    </dxi-column>
    <dxi-column dataField="attivita"
                dataType="string"
                cellTemplate="noteTemplate"
                caption="Attività"
                [visible]="colAttivitaVisible">
    </dxi-column>
    <dxi-column dataField="informatore"
                dataType="string"
                cellTemplate="noteTemplate"
                caption="Informatore"
                [visible]="colInformatoreVisible">
    </dxi-column>
    <dxi-column dataField="note"
                dataType="string"
                cellTemplate="noteTemplate"
                caption="Note">
    </dxi-column>
    <dxi-column dataField="durata">
      <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
    </dxi-column>
    <dxo-summary>
      <dxi-total-item column="durata"
                      summaryType="sum"
                      [customizeText]="customizeTotaleOre">
      </dxi-total-item>
    </dxo-summary>

    <div *dxTemplate="let data of 'noteTemplate'">
      <div class="multiline-note">{{ data.value }}</div>
    </div>

  </dx-data-grid>
</div>
