export const navigationAM = [
  {
    text: 'Investimenti',
    path: '/investments',
    icon: 'product'
  },
  {
    text: 'Richieste Investimenti',
    path: '/investment-requests',
    icon: 'help'
  },
  {
    text: 'Note spese',
    path: '/expenses',
    icon: 'money'
  },
  {
    text: 'Calendario',
    path: '/appointments',
    icon: 'event'
  },
  {
    text: 'Medici',
    path: '/doctors',
    icon: 'user'
  }
];

export const navigationNS = [
  {
    text: 'Investimenti',
    path: '/investments',
    icon: 'product'
  },
  {
    text: 'Richieste Investimenti',
    path: '/investment-requests',
    icon: 'help'
  },
  {
    text: 'Note spese',
    path: '/expenses',
    icon: 'money'
  }
];

export const navigationEmployee = [
  {
    text: 'Note spese',
    path: '/expenses',
    icon: 'money'
  }
];

export const navigation = [
  {
    text: 'Investimenti',
    path: '/investments',
    icon: 'product'
  },
  {
    text: 'Richieste Investimenti',
    path: '/investment-requests',
    icon: 'help'
  },
];

export const navigationLuce = [
  {
    text: 'Investimenti',
    path: '/investments',
    icon: 'product'
  },
];

