import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import notify from 'devextreme/ui/notify';
import { AuthResponseDto, AuthService } from '../../services';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
  loading = false;
  formData: any = {};

  constructor(private authService: AuthService, private router: Router) { }

  async onSubmit(e: Event) {
    e.preventDefault();
    const { email, password } = this.formData;
    this.loading = true;

    this.authService.logIn(email, password).subscribe({
      next: (res: AuthResponseDto) => {

        if (res.isAuthSuccessful) {
          localStorage.setItem("jwt", res.token);
          localStorage.setItem("user", email);
          localStorage.setItem("userId", res.userId);
          localStorage.setItem("nomeUtente", res.nomeUtente);

          if (res.isAreaManager) {
            localStorage.setItem("isAreaManager", "S");
          }
          else {
            localStorage.setItem("isAreaManager", "N");
          }

          if (res.isNoteSpese) {
            localStorage.setItem("isNoteSpese", "S");
          }
          else {
            localStorage.setItem("isNoteSpese", "N");
          }

          if(res.isAdministrator) {
            localStorage.setItem("isAdministrator", "S");
          }
          else {
            localStorage.removeItem("isAdministrator");
          }

          if (res.isDipendente) {
            localStorage.setItem("isDipendente", "S");
          }
          else {
            localStorage.removeItem("isDipendente");
          }

          this.router.navigate(['/']);
        }
        else {
          this.loading = false;
          notify(res.errorMessage, 'error', 2000);
        }

      },
      error: (err: HttpErrorResponse) => {
        this.loading = false;
        notify(err.error.errorMessage, 'error', 2000);
      }
    });
  }
}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule
  ],
  declarations: [ LoginFormComponent ],
  exports: [ LoginFormComponent ]
})
export class LoginFormModule { }
