export interface IInvestmentRequest {
  investmentRequestId: number;
  informatoreId: string | null;
  dataRichiesta: string;
  dataInvestimento: string | null;
  investmentRequestTypeId: number | null;
  note: string | null;
  investmentRequestStateId: number;
  investmentBudgetTypeId: number | null;
  importo: number;
  importato: boolean;
  restaurantName: string | null;
  restaurantPlace: string | null;
  restaurantMail: string | null;
  restaurantPhone: string | null;
  restaurantAttendees: number | null;
  sponsorshipExhibition: boolean | null;
  travelDestination: string | null;
  travelCheckIn: string | null;
  travelCheckOut: string | null;
  travelTreatment: string | null;
  travelRoom: string | null;
  companyVisitDate: string | null;
  companyVisitCheckIn: string | null;
  companyVisitCheckOut: string | null;
  sanitaryMaterialMedicalObject: boolean | null;
  sanitaryMaterialPrescriptionPad: boolean | null;
  sanitaryMaterialPriceList: boolean | null;
  materialDescription: string | null;
  conventionRegistrationName: string | null;
  conventionRegistrationDistrictId: number | null;
  investmentRequestVoucherTypeId: number | null;
  voucherCount: number | null;
}

export class InvestmentRequest implements IInvestmentRequest {
  id!: number;
  investmentRequestId: number;
  informatoreId: string | null = null;
  dataRichiesta: string;
  dataInvestimento: string | null = null;
  investmentRequestTypeId: number | null = null;
  note: string | null = null;
  investmentRequestStateId: number = 1;
  investmentBudgetTypeId: number | null = null;
  importo: number = 0;
  importato: boolean = false;
  restaurantName: string | null = null;
  restaurantPlace: string | null = null;
  restaurantMail: string | null = null;
  restaurantPhone: string | null = null;
  restaurantAttendees: number | null = null;
  sponsorshipExhibition: boolean | null = false;
  travelDestination: string | null = null;
  travelCheckIn: string | null = null;
  travelCheckOut: string | null = null;
  travelTreatment: string | null = null;
  travelRoom: string | null = null;
  companyVisitDate: string | null = null;
  companyVisitCheckIn: string | null = null;
  companyVisitCheckOut: string | null = null;
  sanitaryMaterialMedicalObject: boolean | null = false;
  sanitaryMaterialPrescriptionPad: boolean | null = false;
  sanitaryMaterialPriceList: boolean | null = false;
  materialDescription: string | null = null;
  conventionRegistrationName: string | null = null;
  conventionRegistrationDistrictId: number | null = null;
  investmentRequestVoucherTypeId: number | null = null;
  voucherCount: number | null = null;

  investmentRequestDoctors: IInvestmentRequestDoctor[] = [];

  get kind(): InvestmentRequestKind {
    switch (this.investmentRequestTypeId) {
      case 2:
        return InvestmentRequestKind.Cene;
      case 4:
        return InvestmentRequestKind.MaterialeElettronico;
      case 5:
        return InvestmentRequestKind.MaterialeSanitario;
      case 6:
        return InvestmentRequestKind.VisiteAziendali;
      case 9:
        return InvestmentRequestKind.IscrizioneCongressi;
      case 10:
        return InvestmentRequestKind.SponsorizzazioneCongressi;
      case 11:
        return InvestmentRequestKind.Viaggi;
      case 12:
        return InvestmentRequestKind.BuoniCarburante
      case 13:
        return InvestmentRequestKind.BuoniSpesa
      default:
        return InvestmentRequestKind.MaterialeElettronico;
      }
    }

    getKind(type: number) {
      switch (type) {
        case 2:
          return InvestmentRequestKind.Cene;
        case 4:
          return InvestmentRequestKind.MaterialeElettronico;
        case 5:
          return InvestmentRequestKind.MaterialeSanitario;
        case 6:
          return InvestmentRequestKind.VisiteAziendali;
        case 9:
          return InvestmentRequestKind.IscrizioneCongressi;
        case 10:
          return InvestmentRequestKind.SponsorizzazioneCongressi;
        case 11:
          return InvestmentRequestKind.Viaggi;
        case 12:
          return InvestmentRequestKind.BuoniCarburante
        case 13:
          return InvestmentRequestKind.BuoniSpesa
        default:
          return InvestmentRequestKind.MaterialeElettronico;
        }
  }

  constructor(_investmentRequestId: number) {
    this.investmentRequestId = _investmentRequestId;
    this.dataRichiesta = new Date().toISOString().substring(0, 10);
  }
}

export interface IInvestmentRequestState {
  investmentRequestStateId: number;
  codice: string;
  descrizione: string;
}

export interface IInvestmentRequestType {
  investmentRequestTypeId: number;
  codice: string;
  descrizione: string;
}

export interface IInvestmentRequestDoctor {
  investmentRequestDoctorId: number;
  investmentRequestId: number;
  name: string;
  surname: string;
  investmentRequestSpecializationId: number | null;
  workPlaceId: number | null;
  email: string;
  phone: string;
  taxCode: string | null;
  workPublic: boolean | null;
  workCompany: string | null;
  photo: string | null;
  companion1Name: string | null;
  companion1Surname: string | null;
  companion1Photo: string | null;
  companion2Name: string | null;
  companion2Surname: string | null;
  companion2Photo: string | null;
  travelTreatment: string | null;
  travelRoom: string | null;
  microArea: string | null;
}

export class InvestmentRequestDoctor implements IInvestmentRequestDoctor {
  investmentRequestDoctorId: number;
  investmentRequestId: number;
  name: string = "";
  surname: string = "";
  investmentRequestSpecializationId: number | null = null;
  workPlaceId: number | null = null;
  email: string = "";
  phone: string = "";
  taxCode: string | null = null;
  workPublic: boolean | null = null;
  workCompany: string | null = null;
  photo: string | null = null;
  companion1Name: string | null = null;
  companion1Surname: string | null = null;
  companion1Photo: string | null = null;
  companion2Name: string | null = null;
  companion2Surname: string | null = null;
  companion2Photo: string | null = null;
  travelTreatment: string | null = null;
  travelRoom: string | null = null;
  microArea: string | null = null;

  constructor(_investmentRequestId: number, _investmentRequestDoctorId: number) {
    this.investmentRequestId = _investmentRequestId;
    this.investmentRequestDoctorId = _investmentRequestDoctorId
  }
}

export enum InvestmentRequestKind {
  Cene = 2,
  GrantMedici = 3,
  MaterialeElettronico = 4,
  MaterialeSanitario = 5,
  VisiteAziendali = 6,
  IscrizioneCongressi = 9,
  SponsorizzazioneCongressi = 10,
  Viaggi = 11,
  BuoniCarburante = 12,
  BuoniSpesa = 13,
}
