import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {

  @Output() titleChangedEvent = new EventEmitter<string>();

  @Output() collapseClickedEvent = new EventEmitter<boolean>();
  @Output() refreshClickedEvent = new EventEmitter<void>();
  @Output() chartClickedEvent = new EventEmitter<void>();
  @Output() backClickedEvent = new EventEmitter<void>();

  @Output() collapseVisibleChangedEvent = new EventEmitter<boolean>();
  @Output() refreshVisibleChangedEvent = new EventEmitter<boolean>();
  @Output() chartVisibleChangedEvent = new EventEmitter<boolean>();
  @Output() backVisibleChangedEvent = new EventEmitter<boolean>();

  private _title = "";
  private _collapseVisible = false;
  private _refreshVisible = false;
  private _backVisible = false;
  private _chartVisible = false;

  public get title() {
    return this._title;
  }

  public get collapseVisible() {
    return this._collapseVisible;
  }

  public get refreshVisible() {
    return this._refreshVisible;
  }

  public get backVisible() {
    return this._backVisible;
  }

  public get chartVisible() {
    return this._chartVisible;
  }

  constructor() { }

  CollapseClicked(collapseAll: boolean) {
    this.collapseClickedEvent.emit(collapseAll);
  }

  RefreshClicked() {
    this.refreshClickedEvent.emit();
  }

  ChartClicked() {
    this.chartClickedEvent.emit();
  }

  BackClicked() {
    this.backClickedEvent.emit();
  }

  TitleChanged(title: string) {
    this._title = title;
    this.titleChangedEvent.emit(title);
  }

  CollapseVisibleChanged(visible: boolean) {
    this._collapseVisible = visible;
    this.collapseVisibleChangedEvent.emit(visible);
  }

  RefreshVisibleChanged(visible: boolean) {
    this._refreshVisible = visible;
    this.refreshVisibleChangedEvent.emit(visible);
  }

  ChartVisibleChanged(visible: boolean) {
    this._chartVisible = visible;
    this.chartVisibleChangedEvent.emit(visible);
  }

  BackVisibleChanged(visible: boolean) {
    this._backVisible = visible;
    this.backVisibleChangedEvent.emit(visible);
  }
}
