<dx-scheduler [dataSource]="appointmentsData"
              [remoteFiltering]="true"
              [views]="[{
      type: 'day',
      name: 'Giornaliero',
      maxAppointmentsPerCell: 'auto',
    }, {
      type: 'week',
      name: 'Settimanale',
      maxAppointmentsPerCell: 'unlimited',
    }, {
      type: 'month',
      name: 'Mensile',
      maxAppointmentsPerCell: 3,
    }, {
      type: 'agenda',
      name: 'Agenda'
    }]"
              currentView="month"
              [currentDate]="currentDate"
              [startDayHour]="7"
              [endDayHour]="24"
              [adaptivityEnabled]="true"
              [showAllDayPanel]="false"
              dateSerializationFormat="yyyy-MM-ddTHH:mm:ssZ"
              textExpr="descrizione"
              startDateExpr="startDate"
              endDateExpr="endDate"
              recurrenceRuleExpr="RecurrenceRule"
              recurrenceExceptionExpr="RecurrenceException"
              (onAppointmentFormOpening)="onAppointmentFormOpening($event)">
  <dxo-editing #editingOptions
               [allowAdding]="true"
               [allowDeleting]="true"
               [allowUpdating]="true"
               [allowResizing]="true"
               [allowDragging]="true"></dxo-editing>

  <dxi-resource fieldExpr="appointmentActivityId"
                label="Attività"
                [dataSource]="resourceData"
                [useColorAsDefault]="true">
  </dxi-resource>
 
</dx-scheduler>
