import { Component, HostBinding, OnInit } from '@angular/core';
import { AuthService, ScreenService, AppInfoService } from './shared/services';

// Dictionaries for Italian language
import itMessages from 'devextreme/localization/messages/it.json';
import { locale, loadMessages } from 'devextreme/localization';

import { fader } from './animations';
import { RouterOutlet } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs';
import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    fader
  ]
})
export class AppComponent implements OnInit {
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes).filter(cl => this.screen.sizes[cl]).join(' ');
  }

  constructor(
    private authService: AuthService,
    private screen: ScreenService,
    public appInfo: AppInfoService,
    private swUpdate: SwUpdate) {
    config({ licenseKey });
    loadMessages(itMessages);
    locale(navigator.language);
  }

  isAuthenticated() {
    return this.authService.loggedIn;
  }

  prepareRoute(outlet: RouterOutlet): boolean {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      const updatesAvailable = this.swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(evt => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        })));

      updatesAvailable.subscribe(() => {
        if (confirm("Una nuova versione è disponibile. La si desidera caricare Ora?")) {
          window.location.reload();
        }
      });
    }
  }
}
