import { Component, ViewChild } from '@angular/core';
import { CustomStore, createStore } from 'devextreme-aspnet-data-nojquery';
import { environment } from '../../../environments/environment';
import { Subject, fromEvent, takeUntil } from 'rxjs';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnButtonClickEvent, RowDblClickEvent, RowPreparedEvent, SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'app-appointment-dashboard',
  templateUrl: './appointment-dashboard.component.html',
  styleUrls: ['./appointment-dashboard.component.scss']
})
export class AppointmentDashboardComponent {
  @ViewChild('gridAttivita') gridAttivita!: DxDataGridComponent;
  @ViewChild('gridInformatore') gridInformatore!: DxDataGridComponent;

  attivitaDataSource: CustomStore;
  informatoreDataSource: CustomStore;
  attivitaChartDataSource: CustomStore;
  informatoreChartDataSource: CustomStore;

  backendURL: string = environment.ApiUrl + '/api';

  private unsubscriber: Subject<void> = new Subject<void>();
  inizio: string | number | Date;
  fine: string | number | Date;

  constructor(
    private toolbar: ToolbarService,
    private router: Router,
    private route: ActivatedRoute
            ) {

    let dataRegistrazione = new Date();
    const offset = dataRegistrazione.getTimezoneOffset();
    dataRegistrazione = new Date(dataRegistrazione.getTime() - (offset * 60 * 1000))
    this.fine = dataRegistrazione.toISOString().split('T')[0];
    this.inizio = this.fine.substring(0, 8) + '01';

    const currentUser = localStorage.getItem("userId");

    this.attivitaDataSource = createStore({
      key: 'pk',
      loadUrl: this.backendURL + '/AppointmentDashboard/GetByActivity?areaManager=' + currentUser + '&startDate=' + this.inizio + '&endDate=' + this.fine,
    });

    this.informatoreDataSource = createStore({
      key: 'pk',
      loadUrl: this.backendURL + '/AppointmentDashboard/GetByIsf?areaManager=' + currentUser + '&startDate=' + this.inizio + '&endDate=' + this.fine,
    });

    this.attivitaChartDataSource = createStore({
      key: 'attivita',
      loadUrl: this.backendURL + '/AppointmentDashboard/GetChartByActivity?areaManager=' + currentUser + '&startDate=' + this.inizio + '&endDate=' + this.fine,
    });

    this.informatoreChartDataSource = createStore({
      key: 'informatore',
      loadUrl: this.backendURL + '/AppointmentDashboard/GetChartByIsf?areaManager=' + currentUser + '&startDate=' + this.inizio + '&endDate=' + this.fine,
    });

    this.infoAttivitaClick = this.infoAttivitaClick.bind(this);
    this.infoInformatoreClick = this.infoInformatoreClick.bind(this);
  }

  onAttivitaRowDblClick(e: RowDblClickEvent) {
    this.router.navigate(['/appointment-dashboard-detail', { attivita: this.gridAttivita.selectedRowKeys[0] }]);
  }

  

  onInformatoreRowDblClick(e: RowDblClickEvent) {
    this.router.navigate(['/appointment-dashboard-detail', { informatore: this.gridInformatore.selectedRowKeys[0] }]);
  }


  ngOnInit(): void {
    this.toolbar.TitleChanged("Report Appuntamenti");
    this.toolbar.CollapseVisibleChanged(false);
    this.toolbar.RefreshVisibleChanged(false);
    this.toolbar.BackVisibleChanged(true);
    this.toolbar.ChartVisibleChanged(false);

    this.toolbar.backClickedEvent.pipe(takeUntil(this.unsubscriber)).subscribe((_) => this.Back());

    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe(() => {
      history.pushState(null, '');

    });
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  customizeTotaleOre(cellInfo: { value: string; }) {
    if (cellInfo.value == "0") return "";
    return Number(cellInfo.value).toLocaleString("it-IT", { maximumFractionDigits: 1, minimumFractionDigits: 1 });
  }

  customizeTotaleGiorni(cellInfo: { value: string; }) {
    if (cellInfo.value == "0") return "";
    return Number(cellInfo.value).toLocaleString("it-IT", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  }

  infoAttivitaClick(e: ColumnButtonClickEvent) {
    this.router.navigate(['/appointment-dashboard-detail', { attivita: e.row?.key }]);
  }

  infoInformatoreClick(e: ColumnButtonClickEvent) {
    this.router.navigate(['/appointment-dashboard-detail', { informatore: e.row?.key }]);
  }

  searchClick() {
    const currentUser = localStorage.getItem("userId");

    this.attivitaDataSource = createStore({
      key: 'pk',
      loadUrl: this.backendURL + '/AppointmentDashboard/GetByActivity?areaManager=' + currentUser + '&startDate=' + this.inizio + '&endDate=' + this.fine,
    });

    this.informatoreDataSource = createStore({
      key: 'pk',
      loadUrl: this.backendURL + '/AppointmentDashboard/GetByIsf?areaManager=' + currentUser + '&startDate=' + this.inizio + '&endDate=' + this.fine,
    });

    this.attivitaChartDataSource = createStore({
      key: 'attivita',
      loadUrl: this.backendURL + '/AppointmentDashboard/GetChartByActivity?areaManager=' + currentUser + '&startDate=' + this.inizio + '&endDate=' + this.fine,
    });

    this.informatoreChartDataSource = createStore({
      key: 'informatore',
      loadUrl: this.backendURL + '/AppointmentDashboard/GetChartByIsf?areaManager=' + currentUser + '&startDate=' + this.inizio + '&endDate=' + this.fine,
    });
  }

  Back(): void {
    this.router.navigate(['/appointments']);
  }
}
