<div class="user-panel">
  <div class="user-info">
    <div class="image-container">
      <div class="user-image"></div>
    </div>
    <div class="user-name">{{user?.Username}}</div>
  </div>
  @if (menuMode === 'context') {
    <dx-context-menu
      [items]="menuItems"
      target=".user-button"
      showEvent="dxclick"
      width="210px"
    [position]="{
      my: 'top',
      at: 'bottom'
    }"
      cssClass="user-menu">
    </dx-context-menu>
  }
  @if (menuMode === 'list') {
    <dx-list
      class="dx-toolbar-menu-action"
      [items]="menuItems">
    </dx-list>
  }
</div>
