import { Component, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Expense } from '../../shared/models/expense';
import { UploadedEvent, UploadErrorEvent, ValueChangedEvent } from 'devextreme/ui/file_uploader';
import { DxFileUploaderComponent } from 'devextreme-angular/ui/file-uploader';
import { ColumnEditCellTemplateData,  RowDblClickEvent, SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { ClickEvent } from 'devextreme/ui/button';
import { environment } from '../../../environments/environment';
import { DxDataGridComponent } from 'devextreme-angular';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToolbarService } from '../../shared/services/toolbar.service';

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss']
})
export class ExpensesComponent {
  remoteDataSource: any;
  expenseReasonDS: any;
  paymentTypeDS: any;
  backendURL: string = environment.ApiUrl + '/api';
  imageURL: string = environment.ApiUrl + '/';
  maxDate: Date;
  minDate: Date;

  retryButtonVisible = false;
  @ViewChild('uploadedImage') uploadedImageRef!: HTMLImageElement;
  @ViewChild('fileUploader') fileUploaderRef!: DxFileUploaderComponent;
  @ViewChild(DxDataGridComponent, { static: false }) grid!: DxDataGridComponent;

  selectedRowIndex = -1;
  modificabile = true;

  private unsubscriber: Subject<void> = new Subject<void>();
  
  constructor(
    private toolbar: ToolbarService,
    private sanitizer: DomSanitizer) {

    const currentUser = localStorage.getItem("userId");
    const isDipendente = localStorage.getItem("isDipendente");

    //const addButtonEnabled = false;

    this.remoteDataSource = createStore({
      key: 'expenseId',
      loadUrl: this.backendURL + '/Expenses/GetbyUserId?userName=' + currentUser,
      insertUrl: this.backendURL + '/Expenses/Post',
      updateUrl: this.backendURL + '/Expenses/Put',
      deleteUrl: this.backendURL + '/Expenses/Delete',
    });

    this.expenseReasonDS = createStore({
      key: 'expenseReasonId',
      loadUrl: this.backendURL + '/ExpenseReasons/Get',
    });

    if (isDipendente == "S") {
      this.paymentTypeDS = createStore({
        key: 'paymentTypeId',
        loadUrl: this.backendURL + '/PaymentTypes/GetForEmployee',
      });
    }
    else {
      this.paymentTypeDS = createStore({
        key: 'paymentTypeId',
        loadUrl: this.backendURL + '/PaymentTypes/Get',
      });
    }

    const today = new Date();
    this.minDate = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    this.maxDate = new Date(today.getFullYear() + 1, today.getMonth(), 1);
  }

  getPdfURL(pdfUrl: string): SafeResourceUrl {
    const fullUrl = 'https://docs.google.com/viewer?url=' + this.imageURL + '/' + pdfUrl + '&embedded=true';

    const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fullUrl);

    return safeUrl;
  }

  onInitNewRow(e: { data: { userName: string | null; data: Date | null; fattura: boolean | null }; }) {
    e.data.userName = localStorage.getItem("userId");
    e.data.fattura = false;
    e.data.data = new Date();
  }

  onRowDblClick(e: RowDblClickEvent) {
    this.selectedRowIndex = e.rowIndex;
    this.grid.instance.editRow(this.selectedRowIndex);
    this.grid.instance.deselectAll();
  }

  editRow() {
    this.grid.instance.editRow(this.selectedRowIndex);
    this.grid.instance.deselectAll();
  }

  deleteRow() {
        this.grid.instance.deleteRow(this.selectedRowIndex);
        this.grid.instance.deselectAll();
  }

  addRow() {
    this.grid.instance.addRow();
    this.grid.instance.deselectAll();
  }

  selectedChanged(e: SelectionChangedEvent) {
    let controllata = false;
    if (e.selectedRowsData.length > 0 && e.selectedRowsData[0].controllata) controllata = true;

    this.modificabile = !controllata;

    this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
  }

  onClick(e: ClickEvent): void {
    // The retry UI/API is not implemented. Use the private API as shown at T611719.
    const fileUploaderInstance = this.fileUploaderRef.instance;
    // @ts-ignore
    for (let i = 0; i < fileUploaderInstance._files.length; i++) {
      // @ts-ignore
      delete fileUploaderInstance._files[i].uploadStarted;
    }
    fileUploaderInstance.upload();
  }

  onValueChanged(e: ValueChangedEvent): void {
    const reader: FileReader = new FileReader();
    reader.onload = (args) => {
      if (typeof args.target?.result === 'string' && this.uploadedImageRef) {
        this.uploadedImageRef.src = args.target.result;
      }
    };
    reader.readAsDataURL(e.value![0]); // convert to base64 string
  }

  onUploaded(e: UploadedEvent, cellInfo: ColumnEditCellTemplateData<Expense, number>): void {

    if (e.request.responseText.toLowerCase().endsWith(".pdf")) {
      cellInfo.setValue('pdfs/expenses/' + e.request.responseText);
    }
    else {
      cellInfo.setValue('images/expenses/' + e.request.responseText);
    }

    this.retryButtonVisible = false;
  }

  onUploadError(e: UploadErrorEvent): void {
    const xhttp = e.request;
    if (xhttp.status === 400) {
      e.message = e.error.responseText;
    }
    if (xhttp.readyState === 4 && xhttp.status === 0) {
      e.message = 'Connection refused';
    }
    this.retryButtonVisible = true;
  }

  customizeTotaleImporto(cellInfo: { value: string; }) {
    if (cellInfo.value == "0") return "";
    return "Tot Mese: " + Number(cellInfo.value).toLocaleString("it-IT", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  }


  Collapse(collapse: boolean) {
    if (collapse) this.grid.instance.collapseAll(0);
    else this.grid.instance.expandAll(0);
  }
  
  ngOnInit(): void {
    this.toolbar.TitleChanged("Note Spese");
    this.toolbar.CollapseVisibleChanged(true);
    this.toolbar.RefreshVisibleChanged(false);
    this.toolbar.BackVisibleChanged(false);
    this.toolbar.ChartVisibleChanged(false);

    this.toolbar.collapseClickedEvent.pipe(takeUntil(this.unsubscriber)).subscribe((val) => this.Collapse(val));

    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe(() => {
      history.pushState(null, '');
      
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
