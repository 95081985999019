<div class="content-block dx-card responsive-paddings">
  <dx-form id="form"
           labelLocation="left"
           labelMode="floating"
           [formData]="investmentRequestDoctor"
           [showValidationSummary]="true"
           [readOnly]="isFormReadOnly"
           validationGroup="registrationData">
    <dxi-item dataField="surname"
              editorType="dxTextBox"
              [isRequired]="true"
              [colSpan]="2">
      <dxo-label text="Cognome"></dxo-label>
    </dxi-item>
    <dxi-item dataField="name"
              editorType="dxTextBox"
              [isRequired]="true"
              [colSpan]="2">
      <dxo-label text="Nome"></dxo-label>
    </dxi-item>
    <dxi-item dataField="investmentRequestSpecializationId"
              editorType="dxLookup"
              [editorOptions]="specializationEditorOptions"
              [isRequired]="true"
              [colSpan]="2">
      <dxo-label text="Specializzazione"></dxo-label>
    </dxi-item>
    <dxi-item dataField="workPlaceId"
              editorType="dxLookup"
              [editorOptions]="workPlaceEditorOptions"
              [isRequired]="true"
              [colSpan]="2">
      <dxo-label text="Comune in cui si svolge la professione"></dxo-label>
    </dxi-item>
    <dxi-item dataField="microArea"
              editorType="dxTextBox"
              [colSpan]="2">
      <dxo-label text="MicroArea"></dxo-label>
    </dxi-item>
    <dxi-item dataField="email"
              editorType="dxTextBox"
              [editorOptions]="emailEditorOption"
              [isRequired]="true"
              [colSpan]="2">
      <dxo-label text="E-Mail"></dxo-label>
      <dxi-validation-rule type="email"></dxi-validation-rule>
    </dxi-item>
    <dxi-item dataField="phone"
              editorType="dxTextBox"
              [editorOptions]="phoneEditorOption"
              [isRequired]="currentInvestmentRequestKind === allInvestmentRequestKinds.Viaggi || currentInvestmentRequestKind === allInvestmentRequestKinds.VisiteAziendali || currentInvestmentRequestKind === allInvestmentRequestKinds.IscrizioneCongressi"
              [colSpan]="2">
      <dxo-label text="Telefono"></dxo-label>
    </dxi-item>
    @if(currentInvestmentRequestKind === allInvestmentRequestKinds.IscrizioneCongressi) {
    <dxi-item itemType="group"
              caption="Iscrizione Congressi">
      <dxi-item dataField="taxCode"
                editorType="dxTextBox"
                [isRequired]="true"
                [colSpan]="2">
        <dxo-label text="Codice Fiscale"></dxo-label>
      </dxi-item>
      <dxi-item dataField="workPublic"
                editorType="dxCheckBox">
        <dxo-label text="Lavora nel Publico"></dxo-label>
      </dxi-item>
      <dxi-item dataField="workCompany"
                editorType="dxTextBox"
                [colSpan]="2">
        <dxo-label text="Ente di Appartenenza"></dxo-label>
      </dxi-item>
    </dxi-item>
    }
    @if(
    currentInvestmentRequestKind === allInvestmentRequestKinds.Viaggi ||
    currentInvestmentRequestKind === allInvestmentRequestKinds.VisiteAziendali)
    {
    <dxi-item dataField="photo" [template]="'photoFileTemplate'"></dxi-item>
    <dxi-item dataField="companion1Surname"
              editorType="dxTextBox"
              [colSpan]="2">
      <dxo-label text="Cognome Accompagnatore"></dxo-label>
    </dxi-item>
    <dxi-item dataField="companion1Name"
              editorType="dxTextBox"
              [colSpan]="2">
      <dxo-label text="Nome Accompagnatore"></dxo-label>
    </dxi-item>
    <dxi-item dataField="companion1Photo" [template]="'companion1FileTemplate'"></dxi-item>
    <dxi-item dataField="companion2Surname"
              editorType="dxTextBox"
              [colSpan]="2">
      <dxo-label text="Cognome Accompagnatore 2"></dxo-label>
    </dxi-item>
    <dxi-item dataField="companion2Name"
              editorType="dxTextBox"
              [colSpan]="2">
      <dxo-label text="Nome Accompagnatore 2"></dxo-label>
    </dxi-item>
    <dxi-item dataField="companion2Photo" [template]="'companion2FileTemplate'"></dxi-item>

    }
    @if(currentInvestmentRequestKind === allInvestmentRequestKinds.VisiteAziendali)
    {
    <dxi-item dataField="travelTreatment"
              editorType="dxSelectBox"
              [editorOptions]="travelTreatmentEditorOptions"
              [isRequired]="travelTreatmentRequired"
              [colSpan]="2">
      <dxo-label text="Trattamento"></dxo-label>
    </dxi-item>
    <dxi-item dataField="travelRoom"
              editorType="dxSelectBox"
              [editorOptions]="travelRoomEditorOptions"
              [isRequired]="travelTreatmentRequired"
              [colSpan]="2">
      <dxo-label text="Tipologia camera"></dxo-label>
    </dxi-item>
    }

    @if(!isFormReadOnly)
    {
    <dxi-item itemType="group"
              cssClass="buttons-group">

      <dxi-item itemType="button" cssClass="innerButton" [buttonOptions]="aggiungiMedicoButtonOptions">
      </dxi-item>
    </dxi-item>
    }
    <div *dxTemplate="let data of 'photoFileTemplate'">
      <dx-file-uploader #fileUploaderMedico
                        [multiple]="false"
                        accept=".pdf,image/*"
                        uploadMode="instantly"
                        selectButtonText="Carica documento del Medico"
                        [uploadUrl]="backendURL + '/FileUpload/PostInvestmentRequests'"
                        (onValueChanged)="onValueChangedMedico($event)"
                        (onUploaded)="onUploaded($event, data)"
                        (onUploadError)="onUploadError($event)">
      </dx-file-uploader>
    </div>
    <div *dxTemplate="let data of 'companion1FileTemplate'">
      <dx-file-uploader #fileUploaderAccompagnatore1
                        [multiple]="false"
                        accept=".pdf,image/*"
                        uploadMode="instantly"
                        selectButtonText="Carica documento Accompagnatore 1"
                        [uploadUrl]="backendURL + '/FileUpload/PostInvestmentRequests'"
                        (onValueChanged)="onValueChangedAccompagnatore1($event)"
                        (onUploaded)="onUploaded($event, data)"
                        (onUploadError)="onUploadError($event)">
      </dx-file-uploader>
    </div>
    <div *dxTemplate="let data of 'companion2FileTemplate'">
      <dx-file-uploader #fileUploaderAccompagnatore2
                        [multiple]="false"
                        accept=".pdf,image/*"
                        uploadMode="instantly"
                        selectButtonText="Carica documento Accompagnatore 2"
                        [uploadUrl]="backendURL + '/FileUpload/PostInvestmentRequests'"
                        (onValueChanged)="onValueChangedAccompagnatore2($event)"
                        (onUploaded)="onUploaded($event, data)"
                        (onUploadError)="onUploadError($event)">
      </dx-file-uploader>
    </div>
  </dx-form>
</div>
