<header>
  <dx-toolbar class="header-toolbar">
    @if (menuToggleEnabled) {
      <dxi-item
        location="before"
        widget="dxButton"
        cssClass="menu-button"
              [options]="{
        icon: 'menu',
        stylingMode: 'text',
        onClick: toggleMenu
      }">
      </dxi-item>
    }
    @if (title) {
      <dxi-item location="before"
        cssClass="header-title"
        [text]="title">
      </dxi-item>
    }
    @if (chartVisible) {
      <dxi-item location="after"
        widget="dxButton"
        [options]="chartButtonOptions"
        >
      </dxi-item>
    }
    @if (backVisible) {
      <dxi-item location="after"
        widget="dxButton"
        [options]="backButtonOptions"
        >
      </dxi-item>
    }
    @if (refreshVisible) {
      <dxi-item location="after"
        widget="dxButton"
        [options]="refreshButtonOptions"
        >
      </dxi-item>
    }
    @if (collapseVisible) {
      <dxi-item location="after"
        widget="dxButton"
        [options]="collapseButtonOptions"
        >
      </dxi-item>
    }
    @if (expandVisible) {
      <dxi-item location="after"
        widget="dxButton"
        [options]="expandButtonOptions"
        >
      </dxi-item>
    }
    <dxi-item location="after"
      locateInMenu="auto"
      menuItemTemplate="menuItem">
      <div *dxTemplate="let data of 'item'">
        <dx-button class="user-button authorization"
          width="40px"
          height="100%"
          stylingMode="text">
          <div *dxTemplate="let data of 'content'">
            <app-user-panel [user]="user" [menuItems]="userMenuItems" menuMode="context"></app-user-panel>
          </div>
        </dx-button>
      </div>
    </dxi-item>
  </dx-toolbar>
</header>
