import { Component, ViewChild } from '@angular/core';
import { CustomStore, createStore } from 'devextreme-aspnet-data-nojquery';
import { environment } from '../../../environments/environment';
import { takeUntil } from 'rxjs/operators';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { RowDblClickEvent, RowPreparedEvent, SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { Subject, fromEvent } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-investment-requests',
  templateUrl: './investment-requests.component.html',
  styleUrl: './investment-requests.component.scss'
})
export class InvestmentRequestsComponent {
  selectedRowIndex = -1;
  investmentRequestDataSource: CustomStore;
  investmentRequestTypesDataSource: CustomStore;
  informatoriDataSource: CustomStore;
  investmentBudgetTypesDataSource: CustomStore;
  investmentRequestStatesDataSource: CustomStore;

  backendURL: string = environment.ApiUrl + '/api';
  isAreaManager = (localStorage.getItem("isAreaManager") == "S");
  isAdministrator = (localStorage.getItem("isAdministrator") == "S");
  maxDate: Date;
  minDate: Date;
  modificabile = true;
  completo = true;

  @ViewChild(DxDataGridComponent) grid!: DxDataGridComponent

  private unsubscriber: Subject<void> = new Subject<void>();

  constructor(
    private toolbar: ToolbarService,
    private router: Router) {

    const currentUser = localStorage.getItem("userId");

    const today = new Date();
    this.minDate = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    this.maxDate = new Date(today.getFullYear() + 1, today.getMonth(), 1);

    this.investmentRequestDataSource = createStore({
      key: 'investmentRequestId',
      loadUrl: this.backendURL + '/InvestmentRequests/GetbyUserId?userName=' + currentUser,
      deleteUrl: this.backendURL + '/InvestmentRequests/Delete',
    });


    this.investmentRequestTypesDataSource = createStore({
      key: 'investmentTypeId',
      loadUrl: this.backendURL + '/InvestmentRequestTypes/Get'
    });

    this.investmentRequestStatesDataSource = createStore({
      key: 'investmentRequestStateId',
      loadUrl: this.backendURL + '/InvestmentRequestStates/Get'
    });

    this.investmentBudgetTypesDataSource = createStore({
      key: 'investmentBudgetTypeId',
      loadUrl: this.backendURL + '/InvestmentBudgetTypes/Get',
    });

    this.informatoriDataSource = createStore({
      key: 'userId',
      loadUrl: this.backendURL + '/Utenti/GetAgenti?userName=' + currentUser,
    });
  }

  editRow() {
    const rowKey = this.grid.instance.getKeyByRowIndex(this.selectedRowIndex);

    if (rowKey) {
      this.router.navigate(['/investment-request', { requestId: rowKey }]);
    }
  }

  deleteRow() {
    this.grid.instance.deleteRow(this.selectedRowIndex);
    this.grid.instance.deselectAll();
  }

  addRow() {
    this.router.navigate(['/investment-request']);
  }

  selectedChanged(e: SelectionChangedEvent) {
    let importata = false, approvata = false;
    if (e.selectedRowsData.length > 0) {
      if (e.selectedRowsData[0].investmentRequestStateId) {
        importata = (e.selectedRowKeys[0].investmentRequestStateId > 2);
        approvata = (e.selectedRowKeys[0].investmentRequestStateId > 1);
      }
    }

    if (importata || (approvata && !this.isAreaManager)) {
      this.modificabile = false;
    }
    else {
      this.modificabile = true;
    }

    this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
  }

  onRowDblClick(e: RowDblClickEvent) {
    this.selectedRowIndex = e.rowIndex;
    this.editRow();
  }

  onRowPrepared(e: RowPreparedEvent) {
    if (e.rowType === "group" && e.groupIndex === 0 && e.rowElement && e.rowElement.childNodes && e.rowElement.childNodes.length > 0) {

      const tableSectionElement = e.rowElement as HTMLTableSectionElement;

      if (tableSectionElement) {

        if (e.values[0]) {
          e.rowElement.childNodes.forEach(f => {
            const html = f as HTMLTableCellElement;
            html.classList.add("group-approvato");
          });
        }
        else {
          e.rowElement.childNodes.forEach(f => {
            const html = f as HTMLTableCellElement;
            html.classList.add("group-nonapprovato");
          });
        }
      }
    }
    if (e.rowType === "group" && e.groupIndex === 1 && e.rowElement && e.rowElement.childNodes && e.rowElement.childNodes.length > 0) {

      const tableSectionElement = e.rowElement as HTMLTableSectionElement;

      if (tableSectionElement) {
        e.rowElement.childNodes.forEach(f => {
          const html = f as HTMLTableCellElement;
          html.classList.add("group-informatore");
        });
      }
    }
  }

  customizeTotaleImporto(cellInfo: { value: string; }) {
    if (cellInfo.value == "0") return "";
    return Number(cellInfo.value).toLocaleString("it-IT", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  }

  Collapse(collapse: boolean) {
    if (collapse) {
      this.grid.instance.collapseAll(1);
      this.grid.instance.collapseAll(0);
    }
    else {
      this.grid.instance.expandAll(1);
      this.grid.instance.expandAll(0);
    }
  }

  Refresh() {
    this.grid.instance.refresh();
  }


  ngOnInit(): void {
    this.toolbar.TitleChanged("Richieste Investimenti");
    this.toolbar.CollapseVisibleChanged(true);
    this.toolbar.RefreshVisibleChanged(true);
    this.toolbar.BackVisibleChanged(false);
    this.toolbar.ChartVisibleChanged(false);

    this.toolbar.refreshClickedEvent.pipe(takeUntil(this.unsubscriber)).subscribe(() => this.Refresh());
    this.toolbar.collapseClickedEvent.pipe(takeUntil(this.unsubscriber)).subscribe((val) => this.Collapse(val));

    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
